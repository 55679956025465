.SaleHistory {
    width        : 86.6%;
    max-width    : 796px;
    /* margin       : auto auto 2rem; */
    height       : 100%;
    /* margin-top: .5rem; */
    /* position     : relative; */
    /* overflow-y: scroll; */
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */
    overflow: hidden;
}

.SaleHistoryHeader {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    overflow: hidden;
}

.BackIconSaleHistory {
    margin-right   : 16px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.BackIconSaleHistoryText {
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.SaleHistoryBody {
    width        : 100%;
    margin-top   : 1px;
    /* overflow-y: scroll; */
}

.SaleHistoryItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 10rem;
}



.SaleHistoryItemInner {
    width          : 95%;
    margin         : auto;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    /* padding     :0 16px; */
    height         : 48px;
}

.SaleHistoryItemTitle {
    color      : #616161;
    font-size  : 14px;
    font-weight: 400;
    font-family: "Inter" !important;
}

.SaleHistoryItemValue {
    color         : #212121;
    font-size     : 14px;
    font-weight   : 400;
    font-family: "Inter" !important;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.SaleHistoryItemValuePic {
    margin-left: 4px;
}

.SaleHistoryItemValueToken {
    color         : #1976D2;
    font-size     : 14px;
    font-weight   : 400;
    font-family: "Inter" !important;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    cursor        : pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.HistoryEmpty {
    width          : 100%;
    height         : 80vh;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    overflow       : hidden !important;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.HistoryEmptyText {
    font-size  : 16px;
    font-weight: 400;
    font-family: "Inter" !important;
    color      : #616161;
    margin-top : 24px;
}


@media (min-width:836px) {
    #scrollableDiv {
        height       : 91vh;
        /* overflow-y: auto !important;
        overflow-x   : visible !important; */
        width        : 100%;
        margin       : auto;
        overflow     : auto;
    }

    .SaleHistoryItem {
        width           : 95%;
        /* height       : 328px; */
        border          : 1px solid #E0E0E0;
        border-radius   : 16px;
        margin-bottom   : 16px;
        padding         : 8px 16px;
    }
}

@media (max-width:835px) {
    #scrollableDiv {
        height       : 100vh;
        /* overflow-y: auto !important;
        overflow-x   : visible !important; */
        width        : 100%;
        margin       : auto;
        overflow     : auto;
    }

    .SaleHistoryItem {
        width           : 94%;
        /* height       : 328px; */
        border          : 1px solid #E0E0E0;
        border-radius   : 16px;
        margin-bottom   : 16px;
        padding         : 8px 8px;
    }
}

.infinite-scroll-component__outerdiv {
    width   : 100%;
    overflow: visible !important;
}

.infinite-scroll-component {
    overflow: visible !important;
}