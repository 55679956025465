.RegisterGetPassword {
    width   : 100%;
    height  : 100vh;
    position: relative
}

.RegisterPasswordInput {
    width: 100%
}

.RegisterPasswordTextField {
    width     : 100%;
    margin-top: 32px !important;
    height    : 56px !important
}

.IconShowHide,
.IconShowHideError,
.Visibility {
    height: 24px !important;
    width : 24px !important
}

.IconShowHide {
    color: #1976d2 !important
}

.IconShowHideError {
    color: #d32f2f !important
}

.PrivacyLink,
.TermLink {
    color          : #1976d2;
    font-size      : 12px !important;
    text-decoration: none
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height : 56px !important;
    padding: 0 14px !important
}

.RegisterFooterLinks,
.RegisterLinkDisable {
    width      : 100%;
    height     : 48px;
    font-family: "Inter" !important;

    display    : flex
}

.css-1wc848c-MuiFormHelperText-root {
    font-size: 12px !important
}

.RegisterFooterLinks {
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    font-size      : 10px;
    font-weight    : 400;
    border-top     : 1px solid #e7e7e7
}

.RegisterLinkDisable {
    background     : #e0e0e0;
    color          : #616161;
    font-size      : 16px;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    text-decoration: none;
    border-radius  : 33px;
    margin-bottom  : 22px;
    outline        : 0;
    border         : none;
    cursor         : not-allowed
}