.App {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: space-around;
    margin         : auto;
    height         : 100%;

    font-family: "Inter" !important;
    background     : #fff !important
}

#root {
    height  : 100%;
    position: fixed;
    overflow: auto;
    width   : 100%;
    font-family: "Inter" !important;
}

input,
label {
    /* font-family: Product Sans !important */
    font-family: "Inter" !important;
}

@media (prefers-color-scheme:dark) {
    body {
        background-color: #fff
    }
}