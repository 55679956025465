.GameOver {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : start;
    height         : 100%;
    margin         : auto;
    overflow       : scroll;
    margin-bottom  : 4rem;
    padding-bottom : 1rem
}

.GameOverContent {
    padding-top    : 24px;
    width          : 91%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    max-width      : 768px;
    margin         : 0 auto
}

.GameOverContentTitle {
    color      : #212121;
    font-weight: 500;
    font-family: "Inter" !important;
    font-size  : 20px;
    margin-top : 24px
}

.GameOverContentFooter {
    color      : #616161;
    font-weight: 400;
    font-family: "Inter" !important;
    font-size  : 14px;
    margin-top : 24px
}