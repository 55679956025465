.Sale {
    width          : 100%;
    height         : 100%;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    overflow: hidden;
}

.SaleHeader {
    width     : 100%;
    /* background: #EFF7FE; */
    height    : 56px;
}


@media (min-width: 980px) {
  
    .SaleContent {
        /* height               : 100%; */
        /* width                : 94%; */
        /* max-width         : 768px; */
        /* margin               : auto; */

        height: calc(100% + 210px);
        width: 94%;
        /* max-width: 768px; */
         margin:0 auto;
         padding-bottom: 110px;

    }
.EmptySaleContent{
    height: calc(100vh - 210px);
    width: 100%;

}
    .SaleBackGround {
        /* margin: auto; */

        width            : 100%;
        height           : 45%;
        background-repeat: no-repeat;
        background-size  : cover;
        background-image : url('./../Public/Image/Sale/SaleBgBig.svg');
    }
}

@media (max-width: 980px) {
  
    .SaleContent {
        /* height   : 100%;
        width    : 94%;
        max-width: 768px;
        margin   : auto; */
        /* height   : 150%; */
        height: calc(100% + 210px);
        width: 94%;
        /* max-width: 768px; */
         margin:0 auto;
         padding-bottom: 110px;
    }
    .EmptySaleContent{
        height: calc(100vh - 210px);
    width: 100%;


    }
    .SaleBackGround {
        /* margin: auto; */

        width            : 100%;
        height           : 40%;
        background-repeat: no-repeat;
        background-size  : cover;
        background-image : url('./../Public/Image/Sale/SaleBg.svg');
    }
}



.SaleHasPhoneNumberItems {
    width            : 100%;
    display          : flex;
    flex-direction   : column;
    justify-content  : flex-start;
    align-items      : center;
 /* height: 100vh; */
    /* height:calc(100% - 380px); */
/* padding-bottom: 12rem; */
    /* overflow: auto; */

}




.SaleEmptyText {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    font-weight    : 700;
    font-size      : 24px;
    font-family: "Inter" !important;
    line-height    : 2rem;
}

.SaleEmptyText1 {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    font-weight    : 400;
    font-size      : 16px;
    font-family: "Inter" !important;
    text-align     : center;
    margin-top     : 24px;
}

.SaleButtonsFooter {
    width          : 94%;
    max-width      : 768px;
    margin         : auto;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin-top     : 14px;
    position       : fixed;
    bottom         : 95px
}

.SaleButtonsHistory {
    background     : #fff;
    color          : #1976D2;
    font-weight    : 400;
    font-size      : 16px;
    font-family: "Inter" !important;
    outline        : none;
    border         : 1px solid #BDBDBD;
    border-radius  : 30px;
    width          : 48%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.SaleButtonsHistory:hover {
    background: #E3F2FD;
    cursor    : pointer;

}

.SaleButtonsSale {
    background     : #1976D2;
    color          : #fff;
    font-weight    : 400;
    font-size      : 16px;
    font-family: "Inter" !important;
    outline        : none;
    border         : none;
    border-radius  : 30px;
    width          : 48%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.SaleButtonsSale:hover {
    background: #1565C0;
    cursor    : pointer;
}


.SaleHasPhoneNumber {
    width          : 94%;
    margin         : auto;
    max-width      : 768px;
    /* height         : 100%; */
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    
     height: 100%; 
    /* height         : calc(100vh - 13%); */
    overflow       : auto;
    /* height: 100vh; */
    /* padding-bottom: 3rem; */

}

.SaleHasPhoneNumberTitle {
    font-weight    : 700;
    font-size      : 16px;
    font-family: "Inter" !important;
    margin-top     : 20px;
    height         : 48px;
    /* position    : sticky; */
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
}
#scrollableDiv1{
    /* height: 200px !important; */
    /* width: 100%; */
   
}


.PhoneNumberItem {
    width          : 99%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    border         : 1px solid #E0E0E0;
    border-radius  : 8px;
    margin-top     : 16px;
    padding        : 8px 0;
}

.PhoneNumberItemInner {
    width          : 95%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 48px;
    /* margin      : 0.5rem; */

}

.PhoneNumberItemInnerTitle {
    font-weight: 400;
    font-size  : 14px;
    color      : #616161;
    font-family: "Inter" !important;

}

.PhoneNumberItemInnerValue {
    font-weight: 400;
    font-size  : 14px;
    color      : #212121;
    font-family: "Inter" !important;
}
.PhoneNumberItemInnerValue1{
    font-weight: 400;
    font-size  : 14px;
    color      : #616161;
    font-family: "Inter" !important;
}

.PhoneNumberItemInnerConfirm {
    border       : 1px solid #BDBDBD;
    border-radius: 63px;
    background   : #fff;
    padding      : 12px 16px;
  /* width: 84px;
  height: 40px; */
    font-weight  : 500;
    font-size    : 14px;
    color        : #1976D2;
    font-family: "Inter" !important;
}
.disableButton{
    border       : 1px solid #BDBDBD;
    border-radius: 63px;
    background   : #fff;
    padding      : 8px 30px;

    /* width: 84px;
    height: 40px; */
    font-weight  : 500;
    font-size    : 14px;
    color        : #1976D2;
    font-family: "Inter" !important;
    /* opacity: 0.4; */
    pointer-events: none;
   

}
.disableButton:hover{
    cursor: not-allowed;
    pointer-events: none;

}
.PhoneNumberItemInnerConfirm:hover {
    background: #E3F2FD;
    cursor    : pointer;
}

.SaleFooter {
    width     : 100%;
    position  : fixed;
    bottom    : 0;
    background: #EFF7FE;
}

.PhoneNumberItemInnerTimer {
    background      : #E7E7E7;
    /* border       : 1px solid #BDBDBD; */
    border          : none;
    outline         : none;
    border-radius   : 63px;
    /* background   : #fff; */
    padding         : .5rem;
    font-weight     : 500;
    font-size       : 14px;
    color           : #616161;
    font-family: "Inter" !important;
}

/* .PhoneNumberItemInnerTimer:hover {
    background: #E3F2FD;
    cursor    : pointer;
} */