.DeleteAccountContainer {
    width    : 100%;
    max-width: 768px;
    margin   : auto;
    height   : 100dvh;


}

.DeleteAccountBody {
    margin-top     : 32px;
    width          : 91%;
    height         : 90dvh;
    max-width      : 768px;
    margin         : auto;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : center;
    margin-top     : 32px;
   
}

.DeleteAccountImg {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 112px;

}

.DeleteAccountTxtHeader {
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    font-size      : 14px;
    font-family: "Inter" !important;
    font-weight    : 400;
    color          : #212121;
    margin-top     : 40px;
    line-height    : 20px;
}

.DeleteAccountList ul {
    width: 88%;
    margin: auto;
    margin-top: 32px;

}

.DeleteAccountListLI {
    font-family: "Inter" !important;
    font-weight: 400;
    color      : #212121;
    font-size  : 14px;
    line-height: 20px;
}

.DeleteAccountButtons {
    width          : 100%;
    max-width      : 768px;
    /* margin         : auto; */
    /* margin-top     : 40px; */
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
}

.DeleteAccountButtonsCancel {
    color        : #1976D2;
    height       : 48px;
    width        : 50%;
    border-radius: 30px;
    border       : 1px solid #BDBDBD;
    outline      : none;
    font-family: "Inter" !important;
    font-weight  : 500;
    font-size    : 16px;
    background   : #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}
@media (min-width: 980px) {

.DeleteAccountButtonsCancel:hover{
    background: #E3F2FD;
}
.DeleteAccountButtonsDelete:hover{
    background: #1565C0;
}
}
.DeleteAccountButtonsDelete {
    color        : #fff;
    height       : 48px;
    width        : 50%;
    outline      : none;
    font-family: "Inter" !important;
    font-weight  : 500;
    font-size    : 16px;
    background   : #1976D2;
    border-radius: 30px;
    border       : none;
    margin-left  : 8px;
    cursor: pointer;
}

