.ViolatingChannel {
    width          : 94%;
    margin         : auto;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    max-width      : 768px
}

.ViolatingChannelTitle {
    font-size  : 20px;
    font-weight: 500;
    font-family: "Inter" !important;
    color      : #212121;
    margin-top : 28px
}

.ViolatingChannelDes {
    font-size  : 14px;
    font-weight: 400;
    font-family: "Inter" !important;
    color      : #212121;
    margin-top : 24px;
    text-align : center;
    padding    : 0 1rem;
    line-height: 24px
}

.ViolatingChannelFooter {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center
}

.ViolatingChannelButton,
.ViolatingChannelButton2 {
    outline    : 0;
    width      : 48%;
    font-family: "Inter" !important;
    font-size  : 16px;
    font-weight: 500;
    height     : 48px;
    margin     : 24px 0 21px
}

.ViolatingChannelButton {
    background   : #1976d2;
    border       : none;
    border-radius: 30px;
    color        : #fff
}

.ViolatingChannelButton2 {
    background   : #fff;
    border       : 1px solid #e7e7e7;
    border-radius: 30px;
    color        : #1976d2
}