.Intro {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : start;
    height         : 100vh;
}

.IntroHeader {
    margin-top : 40px;
    font-family: "Inter" !important;
    color      : #212121;
    font-size  : 24px;
    font-weight: 700;
    text-align : center;

}

.IntroDescription {
    margin-top : 20px;
    font-family: "Inter" !important;
    color      : #212121;
    font-size  : 16px;
    font-weight: 400;
    text-align : center;


}

.IntroButtons {
    width          : 95%;
    display        : flex;
    flex-direction : row;
    justify-content: space-around;
    max-width      : 768px;
    margin         : auto;
    align-items    : center;
    position       : fixed;
    bottom         : 16px;
    left           : 0;
    right: 0;
}

.IntroSkipButton {
    border          : 1px solid #757575;
    border-radius   : 32px;
    font-family     : "Inter" !important;
    color           : #1976D2;
    font-size       : 16px;
    font-weight     : 500;
    /* width        : 156px; */
    width           : 45%;
    height          : 48px;
    background      : #fff;
}

.IntroNextButton {
    border          : 1px solid #757575;
    border-radius   : 32px;
    background      : #1976D2;
    color           : #fff;
    /* width        : 156px; */
    width           : 45%;

    height     : 48px;
    font-family: "Inter" !important;

    font-size  : 16px;
    font-weight: 500;
}