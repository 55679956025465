.RegisterBodyText,
.RegisterHeaderText {
    color      : #212121;
    
    font-family: "Inter" !important;

}

.RegisterContainer {
    width    : 86.6%;
    max-width: 796px;
    height   : 100vh;
    position : relative
}

.RegisterHeader {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.RegisterBackIcon {
    width          : 48px;
    height         : 48px;
    margin-right   : 24px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

@media (min-width:980px) {
    .RegisterBackIcon:hover {
        background   : #f5f5f5;
        border-radius: 30px;
    font-family: "Inter" !important;

    }

    .BottomSheetLoginButton:hover,
    .RegisterLink:hover {
        cursor    : pointer;
        background: #1565c0
    }

    .BottomSheetCancelButton:hover {
        cursor    : pointer;
        background: #e3f2fd
    }
}

.RegisterBackIconImg {
    width : 24px;
    height: 24px
}

.RegisterHeaderText {
    font-size  : 20px;
    font-weight: 500
}

.RegisterBodyText {
    font-size  : 14px;
    font-weight: 400;
    width      : 100%;
    height     : 21px;
    text-align : center
}

.RegisterBody,
.RegisterEmailInput {
    width     : 100%;
    margin-top: 24px
}

.RegisterEmailTextField,
.RegisterLinkDiv {
    width: 100%
}

.RegisterLink {
    width          : 100%;
    height         : 48px;
    background     : #1976d2;
    color          : #fff;
    font-size      : 16px;
    font-family: "Inter" !important;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    text-decoration: none;
    border-radius  : 33px;
    margin-bottom  : 22px;
    outline        : 0;
    border         : none
}

@media screen and (max-height:400px) {
    .RegisterFooter {
        display: none
    }
}

@media screen and (min-height:400px) {
    .RegisterFooter {
        position: absolute;
        bottom  : 0;
        width   : 100%
    }
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size  : 16px !important;
    color      : #212121 !important;
    font-family: "Inter" !important;

}

.MuiFormHelperText-root {
    font-size     : 12px !important;
    color         : rgb(0 0 0 / .6);
    font-family: "Inter" !important;

    font-weight   : 400;
    font-size     : .75rem;
    line-height   : 1.66;
    letter-spacing: .03333em;
    text-align    : left;
    margin-top    : 3px;
    margin-right  : 14px;
    margin-bottom : 0;
    margin-left   : 14px
}

.css-1wc848c-MuiFormHelperText-root {
    font-size: 12px !important
}

.RegisterBeforeBottomSheet {
    width          : 86%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin         : auto;
    max-width      : 768px;
    font-family: "Inter" !important;
}

.RegisterBeforeBottomSheetHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 72px
}

.RegisterBeforeBottomSheetHeaderText {
    font-size  : 20px;
    color      : #212121;
    margin-left: 8px;
    font-family: "Inter" !important;

    font-weight: 500
}

.RegisterBeforeBottomSheetBody {
    margin-top : 16px;
    width      : 100%;
    color      : #212121;
    font-family: "Inter" !important;

    font-weight: 400;
    text-align : center;
    font-size  : 14px
}

.RegisterBeforeBottomSheetFooter {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between !important;
    align-items    : center;
    margin-top     : 32px;
    margin-bottom  : 32px
}

.BottomSheetCancelButton,
.BottomSheetLoginButton {
    width      : 48%;
    height     : 48px;
    display    : flex;
    font-weight: 500;
    outline    : 0;
   
    font-family: "Inter" !important;

    font-size  : 16px
}

.BottomSheetCancelButton {
    color          : #1976d2;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border         : 1px solid #bdbdbd;
    border-radius  : 50px;
    background     : #fff
}

.BottomSheetLoginButton {
    color          : #fff;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border         : 1px solid #e7e7e7;
    border-radius  : 50px;
    background     : #1976d2;
    text-decoration: none;
    font-family: "Inter" !important;

}

.UserNameChannelBold {
   
    font-family: "Inter" !important;

    font-weight: 700;
    font-size  : 16px;
    margin     : 0 4px
}