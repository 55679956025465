.SetPasswordContainer {
    width          : 94%;
    display        : flex;
    flex-direction : column;
    align-items    : start;
    justify-content: center;
    margin         : auto;
    height         : 72px;
    padding:0 1rem;
}
.SetPasswordContainer:hover{
    background:#F5F5F5;
    cursor: pointer;
}
.SetPasswordTitle {
    color      : #212121;
    font-size  : 16px;
    font-weight: 400;
    font-family: "Inter" !important;

}

.SetPasswordDescription {
    color      : #616161;
    font-size  : 12px;
    font-weight: 400;
    font-family: "Inter" !important;
    margin-top : 8px;
}
.SetPasswordPageTitle{
    width          : 94%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    margin         : auto;
    height         : 72px;
    padding:0 1rem;  
    color      : #212121;
    font-size  : 14px;
    font-weight: 400;
    font-family: "Inter" !important;
    margin-top : 32px;
}