.PaymentContainer {
    width               : 100%;
    max-width           : 768px;
    margin              : auto;
    height              : 100dvh;
    /* display          : flex;
    flex-direction      : column;
    justify-content     : start;
    align-items         : center; */
    /* height           : calc(100vh - 98px);
  max-height            :300px;
  overflow              :scroll;
    overflow            : scroll; */
}

.PaymentBody {
    margin-top                : 82px;
    width                     : 100%;
    /* overflow                  : hidden;
    height                    : unset; */
    /* height                 : 100dvh; */
    /* height                    : 100vh; */
    /* height                 : -webkit-fill-available; */
    /* overflow                  : scroll; */
    -ms-overflow-style        : none;
    /* IE and Edge */
    /* scrollbar-width           : none; */
    /* Firefox */  
     overflow  : hidden !important;
    height    : unset !important;
}

.PaymentBody1 {
    margin-top: 16px;
    width     : 100%;
 height: auto !important;
 overflow: auto !important;

}

.PaymentItems {
    width                     : 100%;
    display                   : flex;
    flex-direction            : column;
    justify-content           : start;
    align-items               : center;
    /* height                 : calc(100vh - 98px); */
    /* height                 : 100vh; */
    /* height                 : fit-content; */
    /* overflow               : hidden; */


}

.PaymentEmptyDiv {
    height         : 80dvh;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
}

.PaymentFilter {
    width          : 91%;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin         : auto;
}

.PaymentFilterItem {
    padding       : 8px 16px;
    border        : 1px solid #757575;
    color         : #616161;
    border-radius : 8px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    margin-right  : 8px;
    font-size     : 14px;
    font-family: "Inter" !important;
    font-weight   : 400;
    cursor        : pointer;
}

.ActiveFilterPayment {
    padding       : 8px 16px;
    border        : none;
    color         : #212121;
    border-radius : 8px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    background    : #E3F2FD;
    margin-right  : 8px;
    border        : 1px solid #E3F2FD;
    font-size     : 14px;
    font-family: "Inter" !important;
    font-weight   : 500;
    cursor        : pointer;
}

.DoneOutlinedIcon1 {
    width       : 18px !important;
    height      : 18px !important;
    margin-right: 8px;
    color       : #212121;
}





.PaymrntItem {
    width          : 91%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    border         : 1px solid #E0E0E0;
    border-radius  : 8px;
    margin-bottom  : 16px;

}

.PaymentItemRow {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 48px;

}

.PaymentKey {
    margin-left: 16px;
    font-size  : 14px;
    font-family: "Inter" !important;
    font-weight: 400;
    color      : #616161;

}

.PaymentValue {
    margin-right   : 16px;
    font-size      : 14px;
    font-family: "Inter" !important;
    font-weight    : 500;
    color          : #212121;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
}

.Token {
    color : #1976D2;
    cursor: pointer;
}

.ShowMore {
    width        : 50px;
    height       : 50px;
    visibility   : hidden;
    border-radius: 100%;
    background   : #fff;
    color        : #1565C0;
    margin       : 0 auto;
    border       : 1px solid #757575;
    outline      : none;
}

.Date {
    text-align     : right;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : end;
}

.DateText {
    font-size  : 14px;
    font-family: "Inter" !important;
    font-weight: 500;
    color      : #212121;
}

.DateTimeText {
    font-size  : 12px;
    font-family: "Inter" !important;
    font-weight: 400;
    color      : #616161;
}

@media (min-width: 980px) {
    .PaymentFilterItem:hover {
        background: #f5f5f5;
    }
}