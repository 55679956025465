.nav {
    height    : 80px;
    display   : flex;
    overflow-x: auto;
    border-top: 1px solid #E0E0E0;
    max-width : 858px;
    margin    : auto;
    background: #EFF7FE
}

a {
    text-decoration: none
}

@media (min-width:980px) {
    a:hover {
        text-decoration: none;
        cursor         : pointer
    }
}

.nav__link {
    display                    : flex;
    flex-direction             : column;
    align-items                : center;
    justify-content            : center;
    flex-grow                  : 1;
    min-width                  : 50px;
    overflow                   : hidden;
    white-space                : nowrap;
    font-family: "Inter" !important;
    font-size                  : 14px;
    color                      : #616161;
    text-decoration            : none;
    -webkit-tap-highlight-color: #fff0;
    transition                 : background-color 0.1s ease-in-out
}

@media (min-width:980px) {
    .nav__link:hover {
        cursor    : pointer;
        background: #F5F5F5
    }

    .nav__link--active:hover {
        background-color: #EFF7FE
    }
}

.nav__link--active {
    color      : #1976D2;
    font-family: "Inter" !important;
    font-weight: 500
}

.nav__icon {
    font-size: 18px
}

.BottomNavigationActiveLink {
    background     : #A8DAFF;
    border-radius  : 16px;
    height         : 32px;
    width          : 56px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.BottomNavigationDeActiveLink {
    background     : inherit;
    border-radius  : 16px;
    height         : 32px;
    width          : 56px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.BottomNavigationLinkImg {
    width  : 24px;
    height : 24px;
    padding: 7px 20px
}

.nav__textActive {
    color      : #212121;
    font-size  : 14px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.nav__text {
    color      : #616161;
    font-size  : 14px;
    font-weight: 400;
    font-family: "Inter" !important;
}

.ProfilePhotoTextContainer {
    height         : 32px;
    width          : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.ProfilePhotoText {
    width          : 24px;
    height         : 24px;
    background     : #F99C4C;
    color          : #fff;
    border-radius  : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.ProfilePhotoImg {
    width          : 24px;
    height         : 24px;
    border-radius  : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}