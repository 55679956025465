.Orders {
    width             : 100%;
    height         : 100vh;
    /* height         : 100vw; */
    /* height            : fit-content; */
    display           : flex;
    flex-direction    : column;
    justify-content   : flex-start;
    align-items       : flex-start;
    position          : relative;
    margin            : 0 auto;

}

.OrdersHeaderBg {
    background: #fff;
    width     : 100%;
    position  : sticky;
    top       : 0;
    border: 1px solid #E0E0E0;

}

.OrdersHeader {
    width          : 91%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 56px;
    max-width      : 768px;
    margin         : auto;
    position       : sticky;
    /* top         : 0; */
}

.OrderHeaderText {
    font-family: "Inter" !important;
    font-weight: 700;
    font-size  : 24px;
    line-height: 29.11px;
}

.OrdersSearchIcon {
    color : #616161;
    cursor: pointer;
    width : 24px !important;
    height: 24px !important;
}

.OrdersHeaderSearch {
    width          : 91%;
    max-width      : 768px;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    margin         : 0 auto;

}

.OrdersHeaderSearchContainer {
    width     : 100%;
    box-shadow: 0px 0px 4.699999809265137px 0px #75757540;
    position  : sticky;
    top       : 0;
    background: #fff;
}

.SearchInputOrdersContainer {
    width: 100%;
}

.SearchInputOrders {
    width       : 100%;
    height      : 50px;
    font-family: "Inter" !important;
    font-weight : 400;
    font-size   : 14px;
    color       : #212121;
    border      : none;
    outline     : none;
    padding-left: 16px;
}

::placeholder {
    color  : #757575;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #757575;
}

.BackIconSearchOrdersContainer {
    width             : 40px;
    height            : 40px;
    /* width          : 48px;
    height            : 48px; */
    display           : flex;
    flex-direction    : row;
    justify-content   : center;
    align-items       : center;
    cursor            : pointer;
}

.IconSearchOrdersContainer {
    width          : 40px;
    height         : 40px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    cursor         : pointer;

}

@media (min-width: 980px) {
    .IconSearchOrdersContainer:hover {
        cursor       : pointer;
        /* background: red; */
        background   : #F5F5F5;
        border-radius: 100%;
    }

    .top:hover {
        background: #E7E7E7;
    }
}

.BackIconSearchOrders {
    width : 24px !important;
    height: 24px !important;
    color : #616161;
    cursor: pointer;
}

.OrdersContent {
    width                                   : 91%;
    /* -ms-overflow-style                   : none; */
    /* IE and Edge */
    /* scrollbar-width                      : none; */
    /* Firefox */
    max-width                               : 768px;
    display                                 : flex;
    flex-direction                          : column;
    align-items                             : start;
    justify-content                         : start;
    margin                                  : 0 auto;
    /* margin-top                           : 16px; */
    /* height                               : 100%; */
    height                               : calc(94vh - 106px);
    /* height                                  : calc(94vh - 15%); */
 
    overflow-y                           : auto !important;
   
}

.OrdersList {
    width          : 100%;
    max-width      : 768px;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : start;
    margin-top     : 16px;
    /* height                                  : calc(94vh - 15%); */
    /* height                                  : 100vh; */
    /* overflow                                : auto; */
    /* overflow-x  : hidden !important; */

    /* margin-bottom  : 85px; */

    /* height         : 100%;  */
    /* overflow  : hidden !important; */
    /* height    : unset !important; */

}

/* .top-to-btm { */
/* width          : 7%; */
/* margin         : auto;
    display        : flex;
    flex-direction : row;
    justify-content: right; */
/* max-width      : 768px; */

/* position: sticky;
    right   : 0;
    bottom  : 100px;
} */

/* .icon-position{
    position: sticky;
    bottom: 40px;
    right: 35px;
    z-index: 20;
  } */
/* .icon-style { */
/* position        : absolute; */
/* bottom          : 40px;
    right           : 35px; */
/* z-index         : 20;
    background-color: #fff;
    border          : 2px solid #fff;
    border-radius   : 50%;
    height          : 50px;
    width           : 50px;
    color           : #fff;
    cursor          : pointer; */
/* animation    : movebtn 3s ease-in-out infinite; */
/* transition      : all .5s ease-in-out;
    box-shadow      : 0px 4px 8.7px 0px #00000040;
    margin          : auto;
}  */

/* .icon-style:hover {
    animation : none;
    background: #E7E7E7; */

/* color: #725034; */
/* border: 2px solid  #725034; */
/* } */
html {
    scroll-behavior: smooth;
}

.top {
    --offset        : 50px;
    place-self      : end;
    /* margin-top   : calc(100vh + var(--offset)); */
    cursor          : pointer;
    /* visual styling */
    background-color: #fff;
    width           : 50px;
    height          : 50px;
    text-align      : center;
    position        : sticky;
    bottom          : 5px;
    left            : 50%;
    right           : 20px;

    text-decoration  : none;
    padding          : 10px;
    font-family: "Inter" !important;
    color            : #fff;
    border-radius    : 100px;
    white-space      : nowrap;
    box-shadow       : 0px 4px 8.7px 0px #00000040;
    border           : none;
    outline          : none;
    -webkit-animation: fadeIn .5s;
    animation        : fadeIn .5s;
    display          : block;
}


@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.topHidden {
    display: none;

    --offset        : 50px;
    place-self      : end;
    /* margin-top   : calc(100vh + var(--offset)); */
    cursor          : pointer;
    /* visual styling */
    background-color: #fff;
    width           : 50px;
    height          : 50px;
    text-align      : center;
    position        : sticky;
    bottom          : 5px;
    left            : 50%;
    right           : 20px;

    text-decoration  : none;
    padding          : 10px;
    font-family: "Inter" !important;
    color            : #fff;
    border-radius    : 100px;
    white-space      : nowrap;
    box-shadow       : 0px 4px 8.7px 0px #00000040;
    border           : none;
    outline          : none;
    opacity          : 0;
    -webkit-animation: fadeOut .5s;
    animation        : fadeOut .5s;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* @keyframes movebtn {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(20px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
} */




/*  */
.Scroller {
    width             : 100%;
    height            : auto !important;
    overflow          : auto !important;
    /* touch-action   : pan-y; */
    position          : relative;
    display           : flex;
    flex-direction    : column;
    justify-content   : end;
}

.OrdersFilter {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin-top     : 16px;
}

.OrdersFilterSingle {
    width          : 91%;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;

    margin    : 0 auto;
    margin-top: 90px;
}

.OrdersFilterType {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border-radius  : 8px;
    border         : 1px solid #757575;
    color          : #616161;
    font-weight    : 400;
    font-size      : 14px;
    font-family: "Inter" !important;
    padding        : 4px 8px 4px 16px;
    margin-right   : 16px;
}

@media (min-width: 980px) {

    .OrdersFilterType:hover {
        cursor    : pointer;
        background: #F5F5F5
    }

    .OrdersFilterStatus:hover {
        cursor    : pointer;
        background: #F5F5F5
    }
}

.OrdersFilterTypeActive {
    background     : #E3F2FD;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border-radius  : 8px;
    border         : none;
    color          : #212121;
    font-weight    : 400;
    font-size      : 14px;
    font-family: "Inter" !important;
    padding        : 4px 8px 4px 16px;
    margin-right   : 16px;
}

.OrdersFilterTypeActive:hover {
    cursor: pointer;

}

.DoneIconMui {
    width       : 18px !important;
    height      : 18px !important;
    margin-right: 8px;
}

.OrdersFilterStatus {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border-radius  : 8px;
    border         : 1px solid #757575;
    color          : #616161;
    font-weight    : 400;
    font-size      : 14px;
    font-family: "Inter" !important;
    padding        : 4px 8px 4px 16px;

}

.OrdersFilterStatus:hover {
    cursor: pointer;
}

.OrdersFilterStatusActive {
    background: #E3F2FD;

    display           : flex;
    flex-direction    : row;
    justify-content   : center;
    align-items       : center;
    border-radius     : 8px;
    /* border         : 1px solid #E0E0E0; */
    color             : #212121;
    font-weight       : 400;
    font-size         : 14px;
    font-family: "Inter" !important;
    padding           : 4px 8px 4px 16px;

}

.OrdersFilterStatusActive:hover {
    cursor: pointer;
}



.OrdersListItem {
    /* height         : 72px;
    width          : 90%;
    padding        : 0 32px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    border-bottom  : 1px solid #E0E0E0; */
    height         : 72px !important;
    width          : 100%;
    /* padding     : 0 1px; */
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    border-bottom  : 1px solid #E0E0E0;
    /* padding     : 0 64px 0 16px ; */
}

@media (min-width: 980px) {
    .OrdersListItem:hover {
        cursor    : pointer;
        background: #F5F5F5;
    }
}

.OrdersListItemUserName {
    color      : #212121;
    font-weight: 500;
    font-size  : 14px;
    font-family: "Inter" !important;
}

.InProgress {
    color          : #1976D2;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin-top     : 8px;

}

.Done {
    color          : #2E7D32;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin-top     : 8px;
}

.Stop {
    color          : #D32F2F;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin-top     : 8px;
}

.Report {
    color          : #616161;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin-top     : 8px;
}

.ReportIcon {
    margin-right: 4px;

}

.InProgressIcon {
    margin-right: 4px;
}

.DoneIcon {
    margin-right: 4px;
}

.StopIcon {
    margin-right: 4px;

}

.OrdersListItemCount {
    color          : #212121;
    font-weight    : 700;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: end;
    align-items    : flex-end;
    height         : 18px;
}

.OrdersListItemTime {
    color          : #616161;
    font-weight    : 400;
    font-size      : 14px;
    font-family: "Inter" !important;
    margin-top     : 8px;
    display        : flex;
    flex-direction : row;
    justify-content: end;
    align-items    : center;
}

.OrdersPersonIcon {
    color      : #616161;
    width      : 18px !important;
    height     : 18px !important;
    margin-left: 4px;
}

.OrdersStatusBottomSheet {
    display        : flex;
    flex-direction : column;
    width          : 100%;
    max-width      : 768px;
    justify-content: center;
    align-items    : center;
    margin         : auto;

}

.OrdersStatusBottomSheetHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #212121;
    font-weight    : 500;
    font-size      : 20px;
    font-family: "Inter" !important;
    margin-top     : 16px;
    margin-bottom  : 16px;
}

.OrdersStatusBottomSheetContent {
    width          : 91%;
    margin         : auto;
    display        : flex;
    flex-direction : column;
    justify-content: start;
    align-items    : center;
    border         : 1px solid #E0E0E0;
    border-radius  : 8px;
    margin-top     : 16px;
    margin-bottom  : 24px;
    border-bottom  : 1px solid #E0E0E0;

}

.UnActiveStatusFilterItem {
    width          : 100%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    color          : #212121;
    font-weight    : 400;
    font-size      : 14px;
    font-family: "Inter" !important;

    border-bottom: 1px solid #E0E0E0;
}

@media (min-width: 980px) {
    .UnActiveStatusFilterItem:hover {
        cursor    : pointer;
        background: #E3F2FD;
    }

    .UnActiveStatusFilterTitle:hover {
        cursor    : pointer;
        background: #E3F2FD;
    }
}

.IconFilterStatus {
    margin: 0 16px;
}

.ActiveStatusFilterItem {
    width          : 100%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    color          : #1565C0;

    font-weight: 400;
    font-size  : 14px;
    font-family: "Inter" !important;

    border-bottom: 1px solid #E0E0E0;
    background   : #E3F2FD;
}

.ActiveStatusFilterTitle {
    width                  : 100%;
    height                 : 48px;
    display                : flex;
    flex-direction         : row;
    justify-content        : start;
    align-items            : center;
    color                  : #1565C0;
    font-weight            : 500;
    font-size              : 14px;
    font-family: "Inter" !important;
    background             : #E3F2FD;
    border-bottom          : 1px solid #E0E0E0;
    border-top-right-radius: 8px;
    border-top-left-radius : 8px;
}

.UnActiveStatusFilterTitle {
    width                  : 100%;
    height                 : 48px;
    display                : flex;
    flex-direction         : row;
    justify-content        : start;
    align-items            : center;
    color                  : #212121;
    font-weight            : 500;
    font-size              : 14px;
    font-family: "Inter" !important;
    background             : #fff;
    border-bottom          : 1px solid #E0E0E0;
    border-top-right-radius: 8px;
    border-top-left-radius : 8px;

}

.BorderRadiusbottom {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius : 8px;
}

.MarginLeft24PX {
    margin-left: 24px;
}

.NotResultOrder {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 70vh;
}

.OrderDetailsSingle {
    width : 100%;
    /* height: calc(100% - 10px); */
    height: 100%;
}

.OrderDetailsSingleHeader {
    width           : 100%;
    display         : flex;
    flex-direction  : row;
    justify-content : flex-start;
    align-items     : center;
    border-bottom   : 1px solid #E0E0E0;
    position        : fixed;
    top             : 0;
    background      : #fff;
    /* margin-bottom: 56px; */

}

.OrderDetailsSingleHeaderInner {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    max-width      : 768px;
    margin         : auto;
    height         : 72px;
    color          : #212121;
    font-weight    : 500;
    font-size      : 20px;
    font-family: "Inter" !important;

}

.ContentSingleItem1 {
    width          : 91%;
    max-width      : 768px;
    margin         : 0 auto;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    border         : 1px solid #E0E0E0;
    border-radius  : 8px;
    height         : 384px;
    margin-top     : 16px;
}
.ContentSingleItem {
    width          : 91%;
    max-width      : 768px;
    margin         : 0 auto;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    border         : 1px solid #E0E0E0;
    border-radius  : 8px;
    height         : 384px;
    margin-top     : 100px;
}
.ContentSingleItemRow {
    width          : 90%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    /* padding     :0 16px; */
    margin         : auto;
}

.ContentSingleItemRowKey {
    color      : #616161;
    font-weight: 400;
    font-size  : 14px;
    font-family: "Inter" !important;
}

.ContentSingleItemRowValue {
    color          : #212121;
    font-weight    : 400;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
}

.DoneSingle {
    color          : #2E7D32 !important;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.InProgressSingle {
    color          : #1976D2 !important;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    cursor         : pointer;
}

.ReportSingle {

    color          : #616161 !important;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.StopSingle {
    color          : #D32F2F !important;
    font-weight    : 500;
    font-size      : 14px;
    font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
}

.InProgressSingleImg,
.ReportSingleImg,
.StopSingleImg,
.DoneSingleImg {
    margin-left: 4px;
}

.CopyIDOrder {
    width      : 16px !important;
    height     : 16px !important;
    color      : #1976D2;
    margin-left: 4px;
}

.BlueOrderTracking {
    color : #1976D2 !important;
    cursor: pointer;
}

.ShowAllOrders {
    width: 100%;
}

.ShowAllOrdersButton {
    width          : 91%;
    max-width      : 768px;
    margin         : 0 auto;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #1976D2;
    font-size      : 16px;
    font-family: "Inter" !important;
    font-weight    : 500;
    border         : 1px solid #BDBDBD;
    border-radius  : 30px;
    margin-top     : 24px;
    height         : 48px;
    background     : #fff;
}

@media (min-width: 980px) {
    .ShowAllOrdersButton:hover {
        cursor    : pointer;
        background: #E3F2FD;
    }

    .BackImageIndetails:hover {
        cursor       : pointer;
        background   : #F5F5F5;
        border-radius: 100%;
    }

    .BlueOrderTracking:hover {
        color: #1565C0 !important
    }
}

.WarningOrders {
    width         : 91%;
    display       : flex;
    flex-direction: column;
    margin        : 0 auto;
}

.WarningOrdersTitle {
    color      : #212121;
    font-size  : 16px;
    font-family: "Inter" !important;
    font-weight: 500;
    margin-top : 32px;
}

.WarningOrdersBody {
    margin-bottom: 59px;
}

.WarningOrdersUl {
    width: 91%;
}

.WarningOrdersUl li {
    color        : #616161;
    font-size    : 14px;
    font-family: "Inter" !important;
    font-weight  : 400;
    margin-bottom: 12px;
    line-height  : 20px;
}

ul {
    display             : block;
    list-style-type     : disc;
    margin-block-start  : 1em;
    margin-block-end    : 1em;
    margin-inline-start : 0px;
    margin-inline-end   : 0px;
    padding-inline-start: 14px;
}

.OrderDetailsSingleContent {
    width: 97%;

    max-width         : 768px;
    display           : flex;
    flex-direction    : column;
    align-items       : start;
    justify-content   : start;
    margin            : 0 auto;
    height         : 100vh;
    overflow          : scroll;
     padding-bottom    : 40px;
 /*   margin-top        : 64px; */
}

/* width: 97%;
    max-width: 768px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin: 0 auto;
    height: 100vh;
    overflow: scroll;
    padding-bottom: 40px;
    overflow: scroll; */
.ProcessingBottomSheet {
    width          : 86%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start;
    color          : #212121;
    font-size      : 14px;
    font-family: "Inter" !important;
    font-weight    : 400;
    margin-bottom  : 12px;
    line-height    : 23px;
    margin-top     : 16px;


}

.ProcessingBottomSheetButton {
    background   : #1976D2;
    color        : #fff;
    font-family: "Inter" !important;
    font-weight  : 500;
    margin-bottom: 16px;
    width        : 100%;
    height       : 48px;
    outline      : none;
    border       : none;
    border-radius: 30px;
    cursor       : pointer;
    margin-top   : 24px;
    margin-bottom: 15px;
}

.ProcessingBottomSheetButton:hover {
    background: #1565C0;
}

.ItemInBottomSheetStop {
    /* display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center; */
    font-family: "Inter" !important;
    font-weight: 400;
    font-size  : 14px;
    color      : #212121;
    text-align : center;
}

#BlueViewText {
    color : #1976D2 !important;
    cursor: pointer;
}

#BlueViewText:hover {
    color   : #1565C0 !important;
    /* color: red !important; */
}