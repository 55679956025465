.TooManyOrdersDes,
.TooManyOrdersTitle {
    color      : #212121;
    font-family: "Inter" !important;
}

.TooManyOrders {
    width          : 94%;
    margin         : auto;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    max-width      : 768px
}

.TooManyOrdersTitle {
    font-size  : 20px;
    font-weight: 500;
    margin-top : 28px
}

.TooManyOrdersDes {
    font-size  : 14px;
    font-weight: 400;
    margin-top : 24px;
    text-align : center;
    padding    : 0 1rem;
    line-height: 24px
}

.TooManyOrdersFooter {
    width: 100%
}

.TooManyOrdersButton {
    background   : #1976d2;
    outline      : 0;
    border       : none;
    border-radius: 30px;
    width        : 100%;
    color        : #fff;
    font-family: "Inter" !important;
    font-size    : 16px;
    font-weight  : 500;
    height       : 48px;
    margin       : 24px 0 21px
}

.TooManyOrdersButton:hover {
    cursor: pointer
}