.TransferContainer {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: space-between;
    max-width      : 768px;
    margin         : auto;
    height         : calc(97dvh - 185px);
    overflow       : auto;
    margin-top     : 56px
}

.TransferContainer1,
.TransferContainer2 {
    width: 100%
}

.TransferTextHeader {
    font-family: "Inter" !important;
    font-weight    : 400;
    font-size      : 14px;
    margin-left    : 8px;
    color          : #212121;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    flex-wrap      : wrap;
    margin         : auto;
    margin-top     : 24px;
    width          : 87%;
    max-width      : 768px
}

.TransferDestination {
    margin    : auto;
    margin-top: 24px;
    width     : 87%
}

.TransferDestinationInput {
    width: 100%
}

.TransferCoinCount {
    margin    : auto;
    margin-top: 32px;
    width     : 87%
}

.TransferCoinCountInput {
    width: 100%
}

.TransferButton {
    margin: auto;
    width : 87%
}

.TransferButtonAction {
    width        : 100%;
    background   : #1976D2;
    color        : #fff;
    font-family: "Inter" !important;
    font-weight  : 500;
    font-size    : 16px;
    border-radius: 30px;
    border       : none;
    height       : 48px;
    outline      : none
}

.TransferButtonActiondisable {
    width        : 100%;
    background   : #E0E0E0;
    color        : #616161;
    font-family: "Inter" !important;
    font-weight  : 500;
    font-size    : 16px;
    border-radius: 30px;
    border       : none;
    height       : 48px;
    outline      : none;
    cursor       : not-allowed
}

.TransferHistory {
    width : 87%;
    margin: auto
}

.TransferHistoryAction {
    width          : 100%;
    background     : #fff;
    color          : #1976D2;
    font-family: "Inter" !important;
    font-weight    : 500;
    font-size      : 16px;
    border-radius  : 30px;
    border         : 1px solid #BDBDBD;
    height         : 48px;
    outline        : none;
    margin-top     : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.RestoreIcon {
    margin-right: 8px
}

.TransferHistoryContainer {
    width    : 100%;
    max-width: 768px;
    margin   : auto;
    height   : 100dvh
}

.TransferHistoryHeader {
    width          : 98%;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin         : auto;
    position       : fixed;
    background     : #fff;
    z-index        : 1;
    padding-top    : 16px;
    padding-bottom : 1px
}

.TransferHistoryHeaderImg {
    margin-top  : 4px;
    margin-right: 8px
}

.TransferHistoryHeaderText {
    color      : #212121;
    font-family: "Inter" !important;
    font-weight: 500;
    font-size  : 20px
}

.TransferHistoryBody {
    width          : 89%;
    height         : fit-content;
    margin         : auto;
    margin-top     : 61px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    padding-bottom : 50px
}

.TransferHistoryBodyItem {
    border       : 1px solid #E0E0E0;
    border-radius: 15px;
    width        : 100%;
    height       : 272px;
    padding      : 8px 0;
    margin-top   : 16px
}

.TransferHistoryInnerItem {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 48px;
    margin         : 0 16px
}

.TransferHistoryInnerItemTime {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 64px;
    margin         : 0 16px
}

.TransferHistoryInnerItemKey {
    color      : #616161;
    font-family: "Inter" !important;
    font-weight: 400;
    font-size  : 14px
}

.TransferHistoryInnerItemValue {
    color         : #212121;
    font-family: "Inter" !important;
    font-weight   : 400;
    font-size     : 14px;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.TransferHistoryInnerItemValueCoulmn {
    color          : #212121;
    font-family: "Inter" !important;
    font-weight    : 400;
    font-size      : 14px;
    display        : flex;
    flex-direction : column;
    align-items    : end;
    justify-content: center
}

.DateTime {
    color      : #616161;
    font-family: "Inter" !important;
    font-weight: 400;
    font-size  : 12px;
    margin-top : 4px
}

.DateDay {
    color      : #212121;
    font-family: "Inter" !important;
    font-weight: 400;
    font-size  : 14px
}

.TransferHistoryInnerItemValueBlue {
    color         : #1976D2;
    font-family: "Inter" !important;
    font-weight   : 400;
    font-size     : 14px;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

@media (min-width:980px) {
    .TransferHistoryAction:hover {
        background: #E3F2FD
    }

    .TransferHistoryInnerItemValueBlue:hover {
        cursor: pointer;
        color : #1565C0
    }

    .TransferButtonAction:hover {
        cursor    : pointer;
        background: #1565C0
    }

    .TransferHistoryHeaderImg:hover {
        background   : #F5F5F5;
        border-radius: 100%
    }
}

.StatusImgTransfer {
    margin-left: 8px
}

.TransferHistoryEmpty {
    width          : 100%;
    height         : 100dvh;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}