.PackageCoinItem {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    height         : 72px;
    cursor         : pointer;
    animation      : fadeIn .5s ease forwards
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.CoinItem {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.CoinItemDigit {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-left    : 16px;
    height         : 44px
}

.CoinItemDigitNewPrice {
    font-size      : 16px;
    font-weight    : 700;
    text-decoration: none;
    color          : #212121;
    font-family: "Inter" !important;
}

.CoinItemDigitOldPrice {
    font-size      : 14px;
    font-weight    : 400;
    margin-top     : 8px;
    text-decoration: none;
    color          : #212121
}

.CoinItemOffDigit {
    width        : fit-content;
    height       : fit-content;
    font-size    : 12px;
    font-weight  : 400;
    color        : #C62828;
    background   : #FFEBEE;
    padding      : 5px;
    border-radius: 30px;
    margin-right : 9px
}

.CoinItemOrderButton {
    font-size     : 14px;
    font-weight   : 500;
    color         : #1565C0;
    border        : 1px solid #BDBDBD;
    padding       : 10px 16px;
    background    : #fff;
    border-radius : 30px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    cursor        : pointer;
    font-family: "Inter" !important;
}

@media (min-width:980px) {
    .CoinItemOrderButton:hover {
        background: #E3F2FD;
        cursor    : pointer
    }
}

.CoinItemOrderOldPrice {
    color          : #757575;
    font-size      : 12px;
    font-weight    : 400;
    text-align     : center;
    margin-top     : 4px;
    text-decoration: line-through
}

.CoinItemOrderButtonIcon {
    margin-right: 4px
}