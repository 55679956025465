.NoticeContentDes,
.NoticeContentTitle {
    color      : #212121;
    font-family: "Inter" !important;
}

@media (min-width:980px) {
    .Notice {
        width    : 94%;
        margin   : auto;
        position : relative;
        height   : 100%;
        max-width: 768px
    }

    .NoticeFooterButton:hover {
        cursor    : pointer;
        background: #1565c0
    }
}

@media (max-width:980px) {
    .Notice {
        width    : 88%;
        margin   : auto;
        position : relative;
        height   : 100%;
        max-width: 768px
    }
}

.NoticeHeader {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    height         : 40px;
    margin-top     : 8px
}

.NoticeHeaderImg {
    width : 24px;
    height: 24px
}

.NoticeContent {
    margin-top     : 8px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.NoticeContentImg {
    width : 129px;
    height: 194px
}

.NoticeContentTitle {
    font-size  : 20px;
    font-weight: 500;
    margin-top : 40px
}

.NoticeContentDes {
    font-size      : 14px;
    font-weight    : 400;
    margin-top     : 16px;
    text-align     : left;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    line-height    : 23px
}

.NoticeContentDes1,
.NoticeContentUl li {
    font-size  : 14px;
    font-weight: 400;
    color      : #212121;
    font-family: "Inter" !important;
    margin-top : 8px
}

.NoticeContentDes1 {
    text-align: left;
    width     : 100%
}

.NoticeContentUl {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
    justify-content: center;
    width          : 100%;
    line-height    : 23px
}

.NoticeFooter {
    position: absolute;
    bottom  : 22px;
    width   : 100%
}

.css-ahj2mt-MuiTypography-root {
    font-size  : 12px !important;
    font-weight: 400 !important;
    color      : #212121 !important;
    font-family: "Inter" !important;
}

.NoticeFooterButton,
.NoticeFooterButtonDisable {
    width      : 100%;
    height     : 48px;
    font-size  : 16px;
    font-weight: 500;
    font-family: "Inter" !important;
    outline    : 0;
    margin-top : 16px
}

.NoticeFooterButton {
    background   : #1976d2;
    border-radius: 30px;
    border       : none;
    color        : #fff;
    cursor       : pointer
}

.NoticeFooterButtonDisable {
    background   : #e0e0e0;
    border-radius: 30px;
    border       : none;
    color        : #616161;
    cursor       : not-allowed
}