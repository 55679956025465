@media (min-width:980px) {
    .JoinButton:hover {
        background: #1565C0;
        cursor    : pointer
    }

    .JoinStop:hover {
        background: #E3F2FD;
        cursor    : pointer
    }

    .JoinSpeed:hover {
        background: #E3F2FD;
        cursor    : pointer
    }

    .AutoJoinNormal:hover {
        background: #E3F2FD;
        cursor    : pointer
    }

    .AutoJoinInFlood:hover {
        background: #1565C0;
        cursor    : pointer
    }

    .NextButton:hover {
        background: #E3F2FD;
        cursor    : pointer
    }
}

.NoInterNetLogo img {
    width : 48px;
    height: 48px
}

.MuiMenu-list:hover {
    background: #fff !important
}

.NoInterNetText {
    font-weight: 400;
    font-size  : 14px;
    line-height: 21px;
    color      : #212121;
    text-align : center;
    font-family: "Inter" !important;
    margin-top : 24px
}

.NoInterNetButton button {
    font-weight  : 500;
    font-size    : 14px;
    line-height  : 21px;
    color        : #fff;
    text-align   : center;
    font-family: "Inter" !important;
    background   : #1976D2;
    padding      : 12px 16px;
    border       : none;
    outline      : none;
    border-radius: 31px;
    margin-top   : 32px;
    cursor       : pointer
}

.NoInterNetButton button:hover {
    background: #1565C0
}

.JoinContainer {
    width          : 85%;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    max-width      : 768px;
    margin         : auto;
    margin-top: -5%;
    animation      : fadeIn .5s ease forwards
}

.JoinHeader {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.JoinHeaderImagesContainer {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : flex-start;
    height         : 127px
}

.JoinHeaderMore {
    width: 1%
}

.JoinHeaderImage {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.JoinHeaderImageChannel {
    border-radius: 100%;
    width        : 127px;
    height       : 127px;
    animation    : fadeIn .5s ease forwards
}

.JoinChannelTitle {
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    color          : #212121;
    text-align     : center;
    margin-top     : 24px;
    display        : flex;
    flex-direction : row;
    justify-content: center
}

.JoinChannelUsername {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    font-size      : 14px;
    font-weight    : 400;
   font-family: "Inter" !important;
    color          : #616161;
    text-align     : center;
    margin-top     : 12px
}

.JoinButtons {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    margin-top     : 24px
}

.JoinButtonContainer {
    width: 100%
}

.JoinButton {
    width          : 100%;
    background     : #1976D2;
    height         : 48px;
    outline        : none;
    border         : none;
    font-size      : 16px;
    color          : #fff;
   font-family: "Inter" !important;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.JoinButtonDeActive {
    width          : 100%;
    background     : #E7E7E7;
    height         : 48px;
    outline        : none;
    border         : none;
    font-size      : 16px;
    color          : #616161;
   font-family: "Inter" !important;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.DoubleCoinYellow {
    margin-left: 4px;
    margin-right: 4px;
}

.AutoJoinAndNext {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between
}

.AutoJoinNormal {
    border         : 1px solid #BDBDBD;
    color          : #1976D2;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #FFF;
    margin-top     : 16px;
    width          : 56%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    outline        : none
}

.AutoJoinNormalDisable {
    border         : 1px solid #E0E0E0;
    color          : #757575;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #FFF;
    margin-top     : 16px;
    width          : 56%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.JoinSpeed {
    border         : 1px solid #BDBDBD;
    color          : #1976D2;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #FFF;
    margin-top     : 16px;
    width          : 56%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    margin-right   : 8px
}

.JoinSpeedIcon {
    margin-right: 8px
}

.JoinStop {
    border         : 1px solid #BDBDBD;
    color          : #D32F2F;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #FFF;
    margin-top     : 16px;
    width          : 56%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.JoinStopIcon {
    margin-right: 8px
}

.AutoJoinInFlood {
    border         : 1px solid #E0E0E0;
    color          : #fff;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #1976D2;
    margin-top     : 16px;
    width          : 56%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    outline        : none;
    margin-right   : 8px
}

.NextButton {
    border         : 1px solid #BDBDBD;
    color          : #1976D2;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #FFF;
    margin-top     : 16px;
    width          : 40%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.NextButtonDisable {
    border         : 1px solid #E0E0E0;
    color          : #757575;
    font-size      : 16px;
    font-weight    : 500;
   font-family: "Inter" !important;
    background     : #FFF;
    margin-top     : 16px;
    width          : 40%;
    height         : 48px;
    border-radius  : 30px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.HeaderContainerJoin {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
    box-shadow     : 0 2px 3px 0 #C2C2C240
}

.HeaderContainerJoinInner {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
    max-width      : 768px
}

.HeaderBackJoin {
    width          : 39px;
    height         : 40px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin         : 0 16px;
    border-radius  : 100%
}

.HeaderBackJoin:hover {
    background: #F5F5F5
}

.HeaderJoinDetailsAccount {
    width          : 100%;
    font-weight    : 700;
    font-size      : 14px;
   font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    height         : 56px
}

.HeaderJoinDetailsAccountItem1 {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    margin-right   : 16px
}

.HeaderJoinDetailsAccountItem2 {
    display       : flex;
    flex-direction: column;
    align-items   : start;
    margin-right  : 16px
}

.HeaderJoinDetailsAccountFirstName {
    font-weight: 400;
    font-size  : 16px;
    font-family: "Inter" !important;
    color      : #212121
}

.HeaderJoinDetailsAccountPhone {
    font-weight    : 400;
    font-size      : 14px;
   font-family: "Inter" !important;
    color          : #616161;
    margin-top     : 4px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.HeaderJoinLogOut {
    color          : #D32F2F;
    font-weight    : 500;
    font-size      : 14px;
   font-family: "Inter" !important;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-right   : 16px;
    cursor         : pointer;
    border-radius  : 24px;
    padding        : 6px 8px
}

.LILOGOUT:hover {
    background: #fff
}

.LogOutImageIcon {
    margin-right: 16px
}

.HeaderJoinDetailsAccountIMG {
    width        : 32px;
    height       : 32px;
    border-radius: 100%
}

.JoinHeaderImageLoader {
    width                            : 128px;
    height                           : 128px;
    margin                           : 0 auto 0;
    border                           : solid 2px #1976d2;
    border-radius                    : 50%;
    border-right-color               : #fff0;
    border-bottom-color              : #fff0;
    -webkit-transition               : all 0.5s ease-in;
    -webkit-animation-name           : rotate;
    -webkit-animation-duration       : 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition                       : all 0.5s ease-in;
    animation-name                   : rotate;
    animation-duration               : 1.0s;
    animation-iteration-count        : infinite;
    animation-timing-function        : linear;
    position                         : absolute
}

.JoinHeaderImageChannelLoader {
    border-radius: 100%;
    width        : 95px;
    height       : 95px;
    margin-bottom: 10px;
    animation    : fadeIn .5s ease forwards
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.JoinHeaderImageChannelLoaderText {
    border-radius  : 100%;
    width          : 95px;
    height         : 95px;
    margin-bottom  : 10px;
    background     : #F99C4C;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    color          : #fff;
    animation      : fadeIn .5s ease forwards
}

.RegisterBeforeBottomSheetHeaderText1 {
    font-size      : 20px;
    color          : #212121;
    margin-left    : 8px;
    font-family: "Inter" !important;
    font-weight    : 500;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.DangerBottomSheet {
    margin-right: 16px
}

.FirstJoinImag {
    width : 124px;
    height: 143px
}

.BottomSheetFirstJoinButton {
    width          : 100%;
    height         : 48px;
    color          : #fff;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-family: "Inter" !important;
    font-weight    : 500;
    outline        : none;
    border         : 1px solid #E7E7E7;
    border-radius  : 50px;
    background     : #1976D2;
    text-decoration: none
}

@media (min-width:980px) {
    .BottomSheetFirstJoinButton:hover {
        cursor    : pointer;
        background: #1565C0
    }
}

.FirstJoinHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 32px
}

.FirstTimeJoinHeader1 {
    font-size      : 20px;
    color          : #212121;
    font-family: "Inter" !important;
    font-weight    : 500;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.WarningFirstTimeJoinHeader1 {
    font-size    : 20px;
    color        : #212121;
    font-family: "Inter" !important;
    font-weight  : 500;
    margin-bottom: 16px
}

.DropDownShowChannel {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%
}

@media (min-width:980px) {
    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        width        : 256px !important;
        border-radius: 8px;
        max-width    : 768px !important;
        margin       : auto !important
    }
}

.css-6hp17o-MuiList-root-MuiMenu-list-root:hover {
    background: #fff !important
}

.css-6hp17o-MuiList-root-MuiMenu-list:hover {
    background: #fff !important
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper:hover {
    background: #fff !important
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background: #fff !important
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:focus {
    background-color: #fff
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    border-radius: 8px !important
}

@media (max-width:980px) {
    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        width        : 256px !important;
        right        : 15px !important;
        border-radius: 8px;
        max-width    : 768px !important;
        margin       : auto !important
    }
}

.DropDownImageChannel {
    width        : 48px;
    height       : 48px;
    border-radius: 50px;
    margin-right : 16px
}

.DropDownImageChannelText {
    width          : 48px;
    height         : 48px;
    border-radius  : 50px;
    margin-right   : 16px;
    background     : #F99C4C;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    color          : #fff
}

.DropDownDesChannel {
    display        : flex;
    flex-direction : column;
    justify-content: center
}

.DropDownDesTitle {
    color      : #212121;
    font-family: "Inter" !important;
    font-size  : 16px;
    font-style : normal;
    font-weight: 500;
    line-height: normal;
    width      : 180px;
    padding    : 0;
    overflow   : hidden
}

.DropDownDesUsername {
    color      : #616161;
    font-family: "Inter" !important;
    font-size  : 12px;
    font-style : normal;
    font-weight: 400;
    line-height: normal
}

.MenuItem {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    color         : #212121;
    font-size     : 16px !important
}

.DropDownImage {
    width : 24px;
    height: 24px;
    margin: 0 24px 0 12px;
    color : #616161
}

.MenuItemContainer {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-start;
    font-size      : 16px !important;
    color          : #212121
}

a {
    color      : #212121;
    font-family: "Inter" !important;
    font-size  : 16px !important
}

.AutoJoinFloodWait {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-size      : 14px;
    color          : #212121;
   font-family: "Inter" !important;
    font-weight    : 500
}

.AutoJoinFloodWaitIcon {
    margin-right: 8px
}

.AutoJoinFloodWaitDes {
    font-size  : 14px;
    color      : #616161;
    font-family: "Inter" !important;
    font-weight: 400;
    margin-top : 16px
}

.FloodWaitTitle {
    color      : #212121;
    font-family: "Inter" !important;
    font-size  : 16px !important;
    font-weight: 500;
    margin-top : 24px;
    text-align : center
}

.FloodWaitDes {
    color      : #616161;
    font-family: "Inter" !important;
    font-size  : 14px !important;
    font-weight: 400;
    margin-top : 16px;
    text-align : center
}

.FloodWaitDes1 {
    color      : #212121;
    font-family: "Inter" !important;
    font-size  : 14px !important;
    font-weight: 500;
    margin-top : 16px;
    text-align : center
}

.AutoJoinFloodWaitButton {
    width          : 100%;
    height         : 48px;
    background     : #E7E7E7;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    outline        : none;
    border         : none;
    border-radius  : 33px;
    margin-top     : 8px
}

.FloodWaitButton {
    width          : 100%;
    height         : 48px;
    background     : #E7E7E7;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    outline        : none;
    border         : none;
    border-radius  : 33px;
    margin-top     : 32px
}

.FloodWaitButtonTimer {
    color       : #212121;
    font-family: "Inter" !important;
    font-size   : 14px !important;
    font-weight : 500;
    text-align  : center;
    margin-right: 8px
}

.FloodWaitButtonText {
    color      : #616161;
    font-family: "Inter" !important;
    font-size  : 14px !important;
    font-weight: 400;
    text-align : center
}

.FloodWaitButtonsNextAutoJoin {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.LoadingForBigCoinContainer {
    position       : relative;
    width          : 138px;
    height         : 138px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-bottom  : 12px
}

.LoadingForBigCoin {
    position: absolute
}

.BigCoin5S {
    width                            : 128px;
    height                           : 128px;
    -webkit-transition               : all 5s ease-in;
    -webkit-animation-name           : rotate;
    -webkit-animation-duration       : 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition                       : all 5s ease-in;
    animation-name                   : rotate;
    animation-duration               : 5s;
    animation-iteration-count        : infinite;
    animation-timing-function        : linear
}

.BigCoin10S {
    width                            : 128px;
    height                           : 128px;
    -webkit-transition               : all 10s ease-in;
    -webkit-animation-name           : rotate;
    -webkit-animation-duration       : 10s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition                       : all 10s ease-in;
    animation-name                   : rotate;
    animation-duration               : 10s;
    animation-iteration-count        : infinite;
    animation-timing-function        : linear
}

.BigCoin2S {
    width                            : 128px;
    height                           : 128px;
    -webkit-transition               : all 2.5s ease-in;
    -webkit-animation-name           : rotate;
    -webkit-animation-duration       : 2.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    transition                       : all 2.5s ease-in;
    animation-name                   : rotate;
    animation-duration               : 2.5s;
    animation-iteration-count        : infinite;
    animation-timing-function        : linear
}

.CoinCount {
    z-index    : 1;
    color      : #000;
    font-family: "Inter" !important;
    font-size  : 40px;
    font-style : normal;
    font-weight: 400;
    line-height: normal
}

.SelectJoinSpeedContainer {
    width         : 100%;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    height        : 100%;
    overflow      : scroll
}

.SelectJoinSpeedTitle {
    color        : #212121;
    text-align   : right;
    font-family: "Inter" !important;
    font-size    : 20px;
    font-style   : normal;
    font-weight  : 500;
    line-height  : normal;
    margin-bottom: 24px;
    margin-top   : 24px
}

.SelectJoinSpeedDes {
    color          : #616161;
    font-family: "Inter" !important;
    font-size      : 12px;
    font-style     : normal;
    font-weight    : 400;
    margin-bottom  : 8px;
    width          : 90%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.SelectJoinSpeedItems {
    width         : 86%;
    display       : flex;
    flex-direction: column;
    max-width     : 650px;
    margin-top    : 24px
}

.JoinSpeedItem {
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.SelectJoinSpeedButtons {
    width          : 86%;
    max-width      : 650px;
    margin-top     : 24px;
    margin-bottom  : 24px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center
}

.SelectJoinSpeedButtonsSecondry {
    border-radius: 50px;
    background   : #FFF;
    width        : 48%;
    color        : #1976D2;
    text-align   : center;
    font-family: "Inter" !important;
    font-size    : 16px;
    font-style   : normal;
    font-weight  : 500;
    line-height  : normal;
    height       : 48px;
    border       : 1px solid #BDBDBD
}

.SelectJoinSpeedButtonsPrimary {
    border-radius: 50px;
    background   : #FFF;
    width        : 48%;
    color        : #FFF;
    text-align   : center;
    font-family: "Inter" !important;
    font-size    : 16px;
    font-style   : normal;
    font-weight  : 500;
    line-height  : normal;
    height       : 48px;
    background   : #1976D2;
    border       : none
}

@media (min-width:980px) {
    .SelectJoinSpeedButtonsPrimary:hover {
        cursor    : pointer;
        background: #1565C0
    }

    .SelectJoinSpeedButtonsSecondry:hover {
        cursor    : pointer;
        background: #E3F2FD
    }
}

.SlideForJoinSpeed {
    width: 100%
}

.ParaRadio {
    width         : 100%;
    height        : 56px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 0;
    margin        : 0;
    font-size     : 14px;
    color         : #212121
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left    : -9999px
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position    : relative;
    padding-left: 28px;
    cursor      : pointer;
    line-height : 20px;
    display     : inline-block;
    color       : #212121
}

[type="radio"]:checked+label:before {
    content      : '';
    position     : absolute;
    left         : 0;
    top          : 0;
    width        : 18px;
    height       : 18px;
    border       : 1px solid #1976D2;
    border-radius: 100%;
    background   : #fff
}

[type="radio"]:not(:checked)+label:before {
    content      : '';
    position     : absolute;
    left         : 0;
    top          : 0;
    width        : 18px;
    height       : 18px;
    border       : 1px solid #212121;
    border-radius: 100%;
    background   : #fff
}

[type="radio"]:not(:checked)+label:before {
    content      : '';
    position     : absolute;
    left         : 0;
    top          : 0;
    width        : 18px;
    height       : 18px;
    border       : 1px solid #212121;
    border-radius: 100%;
    background   : #fff
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content           : '';
    width             : 12px;
    height            : 12px;
    background        : #1976D2;
    position          : absolute;
    top               : 4px;
    left              : 4px;
    border-radius     : 100%;
    -webkit-transition: all 0.2s ease;
    transition        : all 0.2s ease
}

[type="radio"]:not(:checked)+label:after {
    opacity          : 0;
    -webkit-transform: scale(0);
    transform        : scale(0)
}

[type="radio"]:checked+label:after {
    opacity          : 1;
    -webkit-transform: scale(1);
    transform        : scale(1)
}

.range-slider {
    position: relative;
    width   : 100%;
    margin  : auto
}

.LAbleSlider {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    font-family: "Inter" !important;
    font-size      : 12px;
    font-style     : normal;
    font-weight    : 400;
    line-height    : normal;
    margin-top     : 10px
}

.wrapper {
    color         : #4b4949;
    width         : 100%;
    height        : 100px;
    display       : flex;
    flex-direction: column;
    align-items   : center;
    margin        : 0 auto
}

.content {
    width          : 86.66%;
    max-width      : 678px;
    background     : #fff;
    display        : flex;
    flex-direction : column;
    justify-content: center
}

.range {
    display    : flex;
    align-items: center
}

@media only screen and (max-width:600px) {
    .sliderticks {
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        width          : 95%;
        margin         : auto;
        margin-top     : -25px
    }

    #range4 {
        -webkit-appearance: none;
        appearance        : none;
        width             : 100%;
        cursor            : pointer;
        outline           : none;
        border-radius     : 24px;
        height            : 32px;
        background        : #F5F5F5;
        direction         : ltr;
        padding           : 0 !important
    }

    #range4::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance        : none;
        height            : 30px;
        width             : 30px;
        background        : #fff0;
        background-image  : url(../Public/Image/Join/sliderIcon.png);
        background-size   : cover;
        border-radius     : 50%;
        transition        : .2s ease-in-out;
        transform         : rotateZ(var(--thumb-rotate, 0deg));
        z-index           : 10;
        position          : relative
    }
}

@media only screen and (min-width:600px) {
    .sliderticks {
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        width          : 84%;
        margin         : auto;
        margin-top     : -25px;
        height         : 25px
    }

    #range4 {
        -webkit-appearance: none;
        appearance        : none;
        width             : 88%;
        cursor            : pointer;
        outline           : none;
        border-radius     : 24px;
        height            : 32px;
        background        : #F5F5F5;
        direction         : ltr;
        padding           : 0 !important;
        padding           : 0 40px !important
    }

    #range4::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance        : none;
        height            : 30px;
        width             : 30px;
        background        : #fff0;
        background-image  : url(../Public/Image/Join/sliderIcon.png);
        background-size   : cover;
        border-radius     : 50%;
        transition        : .2s ease-in-out;
        transform         : rotateZ(var(--thumb-rotate, 0deg));
        z-index           : 10;
        position          : relative
    }
}

.sliderticks span {
    display        : flex;
    justify-content: center;
    width          : 8px;
    height         : 8px;
    background     : #E0E0E0;
    border-radius  : 40px;
    cursor         : pointer;
    z-index        : 0
}

#range4::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance        : none;
    height            : 30px;
    width             : 30px;
    background        : #fff0;
    background-image  : url(../Public/Image/Join/sliderIcon.png);
    background-size   : cover;
    border-radius     : 50%;
    transition        : .2s ease-in-out;
    transform         : rotateZ(var(--thumb-rotate, 0deg));
    z-index           : 10;
    position          : relative
}

#range4::-moz-range-thumb {
    height          : 30px;
    width           : 30px;
    background      : #fff0;
    background-image: url(../Public/Image/Join/sliderIcon.png);
    z-index         : 10;
    background-size : cover;
    border          : none;
    border-radius   : 50%;
    transform       : rotateZ(var(--thumb-rotate, 0deg));
    transition      : .2s ease-in-out
}

.TooMuchLeftAccountTitle {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    font-family: "Inter" !important;
    font-size      : 16px;
    font-style     : normal;
    font-weight    : 500;
    line-height    : normal;
    margin-top     : 24px;
    color          : #212121;
    align-items    : center
}

.TooMuchLeftAccountDes {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    font-family: "Inter" !important;
    font-size      : 14px;
    font-style     : normal;
    font-weight    : 400;
    line-height    : normal;
    margin-top     : 24px;
    color          : #616161;
    align-items    : center
}

.TooMuchLeftAccountUL {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.FilledAccountTitle {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    font-family: "Inter" !important;
    font-size      : 16px;
    font-style     : normal;
    font-weight    : 500;
    line-height    : normal;
    margin-top     : 24px;
    color          : #212121;
    align-items    : center
}

.FilledAccountDes {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    font-family: "Inter" !important;
    font-size      : 12px;
    font-style     : normal;
    font-weight    : 400;
    line-height    : normal;
    margin-top     : 24px;
    color          : #616161
}

.SelectJoinSpeedAll {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    width          : 86%;
    margin         : auto;
    font-size      : 12;
    height         : 32px;
    max-width      : 650px;
    margin-top     : 16px
}


.AnimationCoinNewUserBottomSheetIcon,
.AnimationXpNewUserBottomSheetIcon {
    width : 12px;
    height: 12px;
    margin-left: 4px;
}

@media only screen and (max-width:600px) {
    .AnimationXp {
        width          : 10px;
        height         : 10px;
        position       : absolute;
        /* animation      : horizontalVibration 4s ease-in-out infinite; */
        animation      : horizontalVibration 4s ease-in-out 1;
        left           : 15%;
        top            : 70%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        font-family: "Inter" !important;
        font-size      : 12px;
        font-style     : normal;
        font-weight    : 700;
        color          : #212121;
        visibility: hidden;

    }

    .AnimationCoin {
        width          : 10px;
        height         : 10px;
        position       : absolute;
        /* animation      : horizontalVibration 4s ease-in-out infinite; */
        animation      : horizontalVibration 4s ease-in-out 1;
        right          : 15%;
        top            : 70%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        font-family: "Inter" !important;
        font-size      : 12px;
        font-style     : normal;
        font-weight    : 700;
        color          : #212121;
        visibility: hidden;

    }
}

@media only screen and (min-width:600px) {
    .AnimationXp {
        width          : 10px;
        height         : 10px;
        position       : absolute;
        /* animation      : horizontalVibration 4s ease-in-out infinite; */
        animation      : horizontalVibration 4s ease-in-out 1;
        left           : 35%;
        top            : 70%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        font-family: "Inter" !important;
        font-size      : 12px;
        font-style     : normal;
        font-weight    : 700;
        color          : #212121;
        visibility: hidden;

    }

    .AnimationCoin {
        width          : 10px;
        height         : 10px;
        position       : absolute;
        /* animation      : horizontalVibration 4s ease-in-out infinite; */
        animation      : horizontalVibration 4s ease-in-out 1;
        right          : 35%;
        top            : 70%;
        display        : flex;
        flex-direction : row;
        justify-content: center;
        align-items: center;
        font-family: "Inter" !important;
        font-size      : 12px;
        font-style     : normal;
        font-weight    : 700;
        color          : #212121;
        visibility: hidden;
    }
}


@keyframes horizontalVibration {


    0% {
        visibility: visible;
        top         : 60%;
        /* transform: translateX(20); */
    }

    100% {
        /* transform: translateX(5px); */
        top: 25%;
        visibility: hidden;
       
    }
}