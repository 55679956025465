.CongratulationsBottomSheet {
    width          : 86%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin         : auto;
    max-width      : 768px
}

.CongratulationsBottomSheetHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 72px
}

.CongratulationsBottomSheetHeaderText {
    font-size  : 20px;
    color      : #212121;
    margin-left: 8px;
    font-family: "Inter" !important;
    font-weight: 500
}

.CongratulationsBottomSheetBody {
    margin-top : 16px;
    width      : 100%;
    color      : #212121;
    font-family: "Inter" !important;
    font-weight: 400;
    text-align : center;
    font-size  : 14px
}

.CongratulationsBottomSheetFooter {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between !important;
    align-items    : center;
    margin-top     : 32px;
    margin-bottom  : 32px
}

.CongratulationsBottomSheetButton {
    width          : 100%;
    height         : 48px;
    display        : flex;
    font-weight    : 500;
    outline        : 0;
    font-family: "Inter" !important;
    font-size      : 16px;
    color          : #fff;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border         : 1px solid #e7e7e7;
    border-radius  : 50px;
    background     : #1976d2;
    text-decoration: none
}

.CongratulationsBottomSheetBodyReward {
    font-family: "Inter" !important;
    font-size  : 16px;
    font-weight: 400;
    color      : #616161
}

.CongratulationsBottomSheetBodyRewardNumber {
    color          : #212121;
    font-family: "Inter" !important;
    font-size      : 18px;
    font-weight    : 700;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 8px
}

.CongratulationsBottomSheetBodyRewardCoin {
    margin: 0 4px 0 4px
}

.CongratulationsBottomSheetBodyRewardStar {
    margin: 0 4px 0 4px
}