.EditPictureContainer {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.EditPictureLink {
    color      : #1976D2;
    font-size  : 14px;
    font-weight: 500;
    font-family: "Inter" !important;
    margin-top : 18px;
    cursor     : pointer;
}

.BottomSheetChange {
    width          : 100%;
    height         : 20vh;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : flex-start;
    margin: auto;
    max-width: 768px;
}

.ChangeBottomSheetPic {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    color          : #616161;
    font-size      : 14px;
    font-weight    : 400;
    font-family: "Inter" !important;
    padding        : 12px 24px;
    cursor         : pointer !important;
    width: 100%;
}
.ChangeBottomSheetPic:hover{
    background: #F5F5F5;
    cursor         : pointer !important;

}
.IconChangeBottomSheetPic {
    width       : 24px !important;
    height      : 24px !important;
    margin-right: 16px;
}

.InputHiddenFile {
    /* visibility: hidden; */
    width     : 100%;
    /* z-index: -1; */
    opacity   : 0;
    /* cursor : pointer !important; */

    position: absolute;
}



.BottomSheetRemoveProfile {
    width             : 100%;
    max-width: 768px;
    margin: auto;
    /* height         : 20vh; */
    display           : flex;
    flex-direction    : column;
    justify-content   : center;
    align-items       : center;
}

.BottomSheetRemoveProfilePic {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    color          : #212121;
    font-size      : 20px;
    font-weight    : 500;
    font-family: "Inter" !important;
    padding        : 16px 24px;

}

.BottomSheetRemoveProfilePicDescription {
    width: 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #212121;
    font-size      : 14px;
    font-weight    : 400;
    font-family: "Inter" !important;
    padding        : 8px 24px;
}

.RemoveProfilePicIcon {
    width       : 24px;
    height      : 24px;
    color       : #616161;
    margin-right: 16px;
}

.CancelRemovePhoto {
    width: 100%;

    background   : #fff;
    color        : #1976D2;
    font-size    : 16px;
    font-weight  : 500;
    font-family: "Inter" !important;
    outline      : none;
    padding      : .6rem 1.5rem;
    border-radius: 35px;
    border       : 1px solid #BDBDBD;
    margin-right: 16px;
    cursor: pointer;
}
.CancelRemovePhoto:hover{
    background: #F5F5F5;
}
.SubmitRemovePhoto {
    width: 100%;
    background   : #1976D2;
    color        : #fff;
    font-size    : 16px;
    font-weight  : 500;
    font-family: "Inter" !important;
    outline      : none;
    padding      : .6rem 1.5rem;
    border-radius: 35px;
    border       : none;
    cursor: pointer;

}
.SubmitRemovePhoto:hover{
    background: #1565C0;
}

.AccountInformationItemEdit {
    height         : 48px;
    width          : 90%;
    padding: 0 16px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
    cursor: pointer;
}
.AccountInformationItemEdit1{
    height         : 48px;
    width          : 90%;
    padding: 0 16px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
    cursor: default;
}
.AccountInformationItemEdit1:hover{
    background: #F5F5F5;
}
.AccountInformationItemEdit:hover{
    background: #F5F5F5;
}