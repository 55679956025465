.BuyCoin {
    width     : 100%;
    margin-top: 66px
}

.BuyCoinContainer {
    width     : 91%;
    max-width : 608px;
    margin    : auto;
    margin-top: 16px
}

.SpecialBuyCoinItem {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    width          : 91%;
    height         : 88px;
    margin         : auto;
    border-radius  : 19px;
    background     : #E3F2FD;
    box-shadow     : 0 2px 3px 0 #00000029;
    margin-bottom  : 16px;
    cursor         : pointer
}

.SpecialBuyCoinItemImg {
    width : 68px;
    height: 49px
}

.SpecialBuyCoinItem1 {
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    margin-left    : 16px
}

.SpecialBuyCoinItem2 {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-right   : 16px
}

.SpecialBuyCoinItem1Count {
    font-family: "Inter" !important;
    font-weight: 500;
    font-size  : 20px;
    margin-left: 8px;
    color      : #1B6199
}

.SpecialBuyCoinItem1CountText {
    font-family: "Inter" !important;
    font-weight: 400;
    font-size  : 12px;
    margin-top : 8px;
    margin-left: 8px;
    color      : #1B6199
}

.SpecialBuyCoinItem2Time {
    font-family: "Inter" !important;
    font-weight  : 400;
    font-size    : 12px;
    margin-bottom: 4px;
    color        : #1B6199
}

.SpecialBuyCoinItem2Offer {
    color          : #1B6199;
    font-size      : 10px;
    font-weight    : 400;
    text-align     : center;
    margin-top     : 4px;
    text-decoration: line-through
}

.SpecialBuyCoinItem2Button {
    padding      : 12px 14px 11px 16px;
    border-radius: 66px;
    border       : 1px solid #1B6199;
    color        : #1B6199;
    font-size    : 14px;
    font-weight  : 500;
    font-family: "Inter" !important;
    background   : #E3F2FD;
    cursor       : pointer
}

.BuyCoinItem {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    height         : 72px;
    margin         : auto;
    cursor         : pointer
}

.BuyCoinItem1 {
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center;
    width          : 100%
}

.BuyCoinItem2 {
    display        : flex;
    flex-direction : row;
    justify-content: end;
    align-items    : center;
    width          : 100%
}

.BuyCoinCount {
    margin-left: 8px;
    font-family: "Inter" !important;
    font-weight: 500;
    font-size  : 16px;
    line-height: 20px
}

.BuyCoinOFF {
    background   : #FFEBEE;
    color        : #C62828;
    font-family: "Inter" !important;
    font-weight  : 400;
    font-size    : 12px;
    line-height  : 15px;
    margin-right : 11px;
    padding      : 4px;
    border-radius: 20px
}

.BuyCoinButtonPrice {
    padding      : 12px 14px 11px 16px;
    border-radius: 66px;
    border       : 1px solid #BDBDBD;
    color        : #1976D2;
    font-size    : 14px;
    font-weight  : 500;
    font-family: "Inter" !important;
    background   : #fff;
    cursor       : pointer
}

.BuyCoinButtonPrice:hover {
    background: #E3F2FD;
    cursor    : pointer
}

.OffPriceUnderButton {
    color          : #757575;
    font-size      : 12px;
    font-weight    : 400;
    text-align     : center;
    margin-top     : 4px;
    text-decoration: line-through;
    font-family: "Inter" !important;
}

.BuyCoinItem1Img {
    width: 40px
}

.BottomSheetSuccessB {
    width          : 100%;
    height         : 48px;
    color          : #fff;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-family: "Inter" !important;
    font-weight    : 500;
    outline        : none;
    border         : 1px solid #E7E7E7;
    border-radius  : 50px;
    background     : #1976D2;
    text-decoration: none
}

@media (min-width:980px) {
    .BottomSheetSuccessB:hover {
        cursor    : pointer;
        background: #1565C0
    }
}

.SuccessBBottomSheetHeaderText {
    font-size      : 20px;
    color          : #212121;
    margin-left    : 8px;
    font-family: "Inter" !important;
    font-weight    : 500;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.ChangePackage {
    width          : 91%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    border         : 1px solid #e0e0e0;
    border-radius  : 16px;
    margin         : auto;
    margin-top     : 16px;
    cursor         : pointer
}

.ChangePackageLogoContainer {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.ChangePackageLogo {
    width      : 45px;
    height     : 43px;
    margin-left: 16px
}

.ChangePackageTexts {
    display        : flex;
    flex-direction : column;
    align-items    : start;
    justify-content: center;
    margin-left    : 16px
}

.ChangePackageText1 {
    font-size  : 14px;
    color      : #212121;
    font-family: "Inter" !important;
    font-weight: 400
}

.ChangePackageText2 {
    font-size  : 12px;
    color      : #616161;
    font-family: "Inter" !important;
    font-weight: 400
}

.ChangePackageimg {
    margin-right: 16px
}