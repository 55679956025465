.AuthContainer {
    width          : 86.6%;
    max-width      : 796px;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : normal
}

.AuthContainerBottomSpace {
    width          : 86.6%;
    max-width      : 796px;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : normal;
    margin-bottom  : 3rem
}
.LoginWithTelgram{
    background-image: url("./../Public/Image/Auth/download 1.svg"); /* The image used */
    /* background-color: #cccccc; Used if the image is unavailable */
    height: 500px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}