.GetPhoneNumberBody,
.GetPhoneNumberInput {
    margin-top: 24px;
    width     : 100%
}

.GetPhoneNumberCountryTextField input,
.ResendCode {
    text-align: center
}

.GetPhoneNumberContainer {
    width    : 94%;
    max-width: 796px;
    height   : 100svh;
    position : relative
}

.GetPhoneNumberHeader {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.GetPhoneNumberBackIcon {
    width          : 48px;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.GetPhoneNumberBackIconImg {
    width : 24px;
    height: 24px
}

.GetPhoneNumberHeaderText {
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
    font-family: "Inter" !important;

}

.GetPhoneNumberBodyText,
.SearchDiv input {
    font-weight: 400;
    font-family: "Inter" !important;

}

.GetPhoneNumberBodyText {
    color          : #212121;
    font-size      : 14px;
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 21px
}

.GetPhoneNumberInput {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.GetPhoneNumberCountryTextField {
    width       : 20%;
    margin-right: 18px !important
}

.GetPhoneNumberPhoneTextField {
    width: 65%
}

.GetCodePhoneTextField {
    width: 100%
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin            : 0
}

.GetPhoneNumberFooter {
    position: absolute;
    bottom  : 0;
    width   : 100%
}

.GetPhoneNumberLink {
    width          : 100%;
    height         : 48px;
    background     : #1976d2;
    color          : #fff;
    font-size      : 16px;
    font-family: "Inter" !important;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    text-decoration: none;
    border-radius  : 33px;
    margin-bottom  : 22px;
    outline        : 0;
    border         : none
}

.GetPhoneNumberTimer,
.SearchDiv {
    flex-direction: row;
    width         : 100%;
    display       : flex
}

.GetPhoneNumberTimer {
    justify-content: center;
    align-items    : center;
    margin-bottom  : 23px;
    font-size      : 14px;
    font-weight    : 500;
    color          : #212121
}

.ResendCode {
    color      : #1976d2;
    font-size  : 14px;
    font-weight: 500;
    cursor     : pointer
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    height : 56px !important;
    padding: 0 !important
}

.SearchDiv {
    align-items: center
}

.SearchDiv input {
    width    : 100%;
    border   : #ffdead;
    height   : 38px;
    font-size: 14px;
    color    : #212121;
    outline  : 0
}

.CloseModalIcon {
    color : #9e9e9e;
    width : 16px;
    height: 16px;
    cursor: pointer
}

.ListCountryItem {
    height         : 48px;
    width          : 100%;
    font-family: "Inter" !important;

    font-size      : 14px;
    cursor         : pointer;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    padding        : 0 24px
}

@media (min-width:980px) {
    .ListCountryItem:hover {
        background: #e0e0e0
    }

    .GetPhoneNumberLink:hover {
        cursor    : pointer;
        background: #1565c0
    }

    .GetPhoneNumberBackIcon:hover {
        background   : #f5f5f5;
        border-radius: 30px
    }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color      : #fff;
    color                 : rgba(0, 0, 0, .87);
    -webkit-transition    : box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    transition            : box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    border-radius         : 4px;
    box-shadow            : 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
    margin                : 32px;
    position              : relative;
    overflow-y            : auto;
    display               : -webkit-box;
    display               : -webkit-flex;
    display               : -ms-flexbox;
    display               : flex;
    -webkit-flex-direction: column;
    -ms-flex-direction    : column;
    flex-direction        : column;
    max-height            : calc(100% - 64px);
    max-width             : 600px;
    height                : 90% !important;
    width                 : 100% !important
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    margin        : 0;
    font-family: "Inter" !important;

    font-weight   : 500;
    font-size     : 1.25rem;
    line-height   : 1.6;
    letter-spacing: .0075em;
    padding       : 0 !important;
    -webkit-flex  : 0 0 auto;
    -ms-flex      : 0 0 auto;
    flex          : 0 0 auto
}

.MuiDialogContent-root {
    padding: 0 !important
}