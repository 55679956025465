.LoginBackIcon,
.LoginHeader {
    flex-direction: row;
    display       : flex
}

input::-ms-reveal,
input::-ms-clear {
    display: none
}

.LoginContainer {
    width    : 86.6%;
    max-width: 796px;
    position : relative;
    height   : 100vh
}

.LoginHeader {
    width          : 100%;
    height         : 56px;
    justify-content: flex-start;
    align-items    : center
}

.LoginBackIcon {
    width          : 48px;
    height         : 48px;
    margin-right   : 24px;
    align-items    : center;
    justify-content: center
}

.LoginBackIconImg {
    width : 24px;
    height: 24px
}

.LoginHeaderText {
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
    font-family: "Inter" !important;

}

.LoginBodyText,
.LoginForgetLink {
    font-size  : 14px;
    font-family: "Inter" !important;

}

.LoginBodyText {
    color          : #212121;
    font-weight    : 400;
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 21px
}

.LoginBody,
.LoginEmailInput {
    width     : 100%;
    margin-top: 24px
}

.LoginForgetBlock,
.LoginPassInput {
    margin-top: 32px;
    width     : 100%
}

.LoginEmailTextField,
.LoginLinkDiv {
    width: 100%
}

.LoginPassTextField {
    width : 100%;
    height: 56px
}

.LoginForgetBlock {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    height         : 40px
}

.LoginForgetLink {
    color          : #1976d2;
    text-decoration: none;
    font-weight    : 500
}

.LoginLink {
    width          : 100%;
    height         : 48px;
    background     : #1976d2;
    color          : #fff;
    font-size      : 16px;
    font-family: "Inter" !important;

    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    text-decoration: none;
    border-radius  : 33px;
    margin-bottom  : 22px;
    outline        : 0;
    border         : none;
    cursor         : pointer
}

@media (min-width:980px) {
    .LoginBackIcon:hover {
        background   : #f5f5f5;
        border-radius: 30px
    }

    .LoginLink:hover {
        background: #1565c0;
        cursor    : pointer
    }
}

@media screen and (max-height:400px) {
    .LoginFooter {
        display: none
    }
}

@media screen and (min-height:400px) {
    .LoginFooter {
        position: absolute;
        bottom  : 0;
        width   : 100%
    }
}