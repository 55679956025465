.AccountInformationContainer {
    width    : 100%;
    max-width: 768px;
    margin   : auto;
    height   : 100vh;
}

.AccountInformationBody {
    margin-top: 82px;
}

.AccountInformationItem {
    height         : 48px;
    width          : 88%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
}

.AccInfoTitle {
    color         : #212121;
    font-size     : 14px;
    font-weight   : 400;
    font-family: "Inter" !important;
    margin-right  : 24px;
    line-height   : 17px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}
.AccInfoTitleEditProfile {
    color         : #616161;
    font-size     : 14px;
    font-weight   : 400;
    font-family: "Inter" !important;
    margin-right  : 24px;
    line-height   : 17px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
}
.EditProfileIcons{
    width: 24px;
    height: 24px;
    color: #616161;
    margin-right: 16px;
}
.AccInfoValue {
    color      : #212121;
    font-size  : 14px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.AccInfoValueEditProfile {
    color      : #1976D2;
    font-size  : 14px;
    font-weight: 500;
    font-family: "Inter" !important;
}