.Coin {
    width          : 100%;
    height         : 100%;
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center
}

.NavContainer {
    width   : 100%;
    position: fixed;
    bottom  : 0
}

.TabsHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border-bottom  : 1px solid #E7E7E7;
    margin         : auto;
    height         : 48px;
    max-width      : 768px;
    position       : fixed;
    background     : #fff;
    top            : 56px;
    z-index        : 1
}

.TabsContainer {
    width          : 100%;
    background     : #fff !important;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    height         : 48px;
    position       : fixed;
    top            : 55px;
    z-index        : 1
}

.TabsHeaderItem {
    width          : 50%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    cursor         : pointer
}

@media (min-width:980px) {
    .TabsHeaderItem:hover {
        background: #F5F5F5
    }
}

.TabSpanDisable {
    display        : flex;
    flex-direction : column;
    justify-content: end;
    align-items    : center;
    height         : 48px;
    width          : fit-content;
    color          : #616161;
    font-family: "Inter" !important;
    font-size      : 14px;
    font-style     : normal;
    font-weight    : 500;
    line-height    : normal;
    height         : 48px
}

.TabSpanActive {
    color          : #1976D2;
    display        : flex;
    flex-direction : column;
    justify-content: end;
    align-items    : center;
    height         : 48px;
    width          : fit-content;
    font-family: "Inter" !important;
    font-size      : 14px;
    font-style     : normal;
    font-weight    : 500;
    line-height    : normal;
    height         : 48px
}

.TabActivateBorder {
    height                 : 3px;
    width                  : 100%;
    background             : #1976D2;
    margin-top             : 12px;
    border-top-left-radius : 16px;
    border-top-right-radius: 16px
}


@media (min-width:768px) {
    .TabDeActivateBorder {
        height    : 3px;
        width     : 100%;
        background: #EFF7FE;
        margin-top: 12px
    }
}
@media (max-width:768px) {
    .TabDeActivateBorder {
        height    : 3px;
        width     : 100%;
        background: #fff;
        margin-top: 12px
    }
}