.JoinBotContainer {
    width : 100%;
    height: 100%;

}

.JoinBot {
    width          : 100%;
    height         : 90%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : center;
    margin-top     : 32px;
    margin-bottom  : 22px;
    font-family: "Inter" !important;

}

.JoinBotHeader,
.JoinBotFooter {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.JoinBotHeaderTitle {
    font-family: "Inter" !important;
    font-size  : 20px;
    font-weight: 500;
    text-align : center;
    margin-top : 32px;
}

.JoinBotHeaderDes {
    font-family: "Inter" !important;
    font-size   : 14px;
    font-weight : 400;
    text-align  : center;
    margin-top  : 24px;
    Line-height : 22px;
    margin-left : 24px;
    margin-right: 24px;
}



@media (min-width: 980px) {
    .JoinBotFooterButton {
        width        : 76%;
        background   : #1976d2;
        color        : #fff;
        font-family: "Inter" !important;
        font-size    : 16px;
        font-weight  : 500;
        height       : 48px;
        outline      : none;
        border       : none;
        border-radius: 30px;
        margin-top   : 16px;
        cursor       : pointer;
        margin       : auto;
    }
    .JoinBotFooterButton:hover {
        background: #1565C0;
    }
}
@media (max-width: 980px) {
    .JoinBotFooterButton {
        width        : 90%;
        background   : #1976d2;
        color        : #fff;
        font-family: "Inter" !important;
        font-size    : 16px;
        font-weight  : 500;
        height       : 48px;
        outline      : none;
        border       : none;
        border-radius: 30px;
        margin-top   : 16px;
        cursor       : pointer;
        margin       : auto;
    }
  
}
.JoinBotFooterLink {
    text-decoration: none;
    color          : #1976D2;
    font-family: "Inter" !important;
    font-size      : 16px;
    font-weight    : 500;
    width          : 100%;
    height         : 48px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center;
    margin-bottom  : 16px;
}

.JoinBotFooterLink:hover {
    color : #1565C0;
    cursor: pointer;
}