.PaymentErrorContainer {
    width     : 100%;
    height    : fit-content;
    background: #FFEBEE;
    margin    : auto;
    padding   : 16px 0;
    z-index   : 0
}

.PaymentError {
    max-width      : 768px;
    width          : 94%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : flex-start;
    margin         : auto;
    position       : relative;
    height         : fit-content
}

.PaymentErrorIcon {
    margin-right: 16px
}

.PaymentErrorTitle {
    color        : #B71C1C;
    font-size    : 16px;
    font-weight  : 400;
    font-family: "Inter" !important;
    margin-bottom: 8px;
    line-height  : 24px
}

.PaymentErrorDes {
    color        : #B71C1C;
    font-size    : 16px;
    font-weight  : 400;
    font-family: "Inter" !important;
    margin-bottom: 16px;
    line-height  : 24px
}

.PaymentErrorToken {
    color        : #B71C1C;
    font-size    : 16px;
    font-weight  : 500;
    font-family: "Inter" !important;
    margin-bottom: 16px
}

.PaymentErrorButtons {
    display        : flex;
    flex-direction : row;
    justify-content: flex-end;
    align-items    : center
}

.PaymentErrorSupport {
    color        : #B71C1C;
    background   : #FFEBEE;
    border       : 1px solid #B71C1C;
    border-radius: 18px;
    margin-right : 16px;
    padding      : 9px 16px
}

.PaymentErrorRetry {
    padding      : 9px 16px;
    color        : #FCF8F8;
    background   : #B71C1C;
    border-radius: 24px;
    border       : none;
    outline      : none
}