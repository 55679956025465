.ForgetPasswordContainer {
    position : relative;
    height   : 100vh;
    width    : 86.6%;
    max-width: 796px
}

.ForgetPasswordHeader {
    width          : 100%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.ForgetPasswordBackIcon {
    width          : 48px;
    height         : 48px;
    margin-right   : 24px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.ForgetPasswordBodyText,
.ForgetPasswordLink {
    
    font-family: "Inter" !important;
    
    flex-direction: row;
    display       : flex
}

@media (min-width:980px) {
    .ForgetPasswordBackIcon:hover {
        background   : #f5f5f5;
        border-radius: 30px
    }

    .ForgetPasswordLink:hover {
        cursor    : pointer;
        background: #1565c0
    }
}

.ForgetPasswordBackIconImg {
    width : 24px;
    height: 24px
}

.ForgetPasswordHeaderText {
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
   
    font-family: "Inter" !important;

}

.ForgetPasswordBodyText {
    color          : #212121;
    font-size      : 14px;
    font-weight    : 400;
    width          : 100%;
    justify-content: center;
    align-items    : center;
    height         : 21px
}

.ForgetPasswordBody {
    width     : 100%;
    margin-top: 24px !important
}

.ForgetPasswordFooter {
    position: absolute;
    bottom  : 0;
    width   : 100%
}

.ForgetPasswordLinkDiv {
    width: 100%
}

.ForgetPasswordLink {
    width          : 100%;
    height         : 48px;
    background     : #1976d2;
    color          : #fff;
    font-size      : 16px;
    justify-content: center;
    align-items    : center;
    text-decoration: none;
    border-radius  : 33px;
    margin-bottom  : 22px;
    outline        : 0;
    border         : none
}

#client-snackbar {
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.CheckIcon {
    color       : #388e3c;
    margin-right: 16px
}