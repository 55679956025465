.CountriesContainer {
    height    : 32px;
    width     : 94%;
    max-width : 768px;
    margin    : auto;
    margin-top: 74px
}

.Countries {
    overflow       : auto;
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

::-webkit-scrollbar {
    display: none
}

::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width   : none
}

.CountryItem {
    border        : 1px solid #757575;
    margin-right  : 8px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 0px 16px;
    height        : 32px !important;
    font-size     : 14px;
    font-weight   : 400;
    color         : #616161;
    border-radius : 8px;
    font-family: "Inter" !important;
    white-space   : nowrap;
    cursor        : pointer
}

@media(min-width:980px) {
    .CountryItem:hover {
        background: #f5f5f5
    }
}

.CountryItemActive {
    margin-right  : 8px;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 0px 16px;
    height        : 33px !important;
    width         : auto;
    height        : 32px;
    font-size     : 14px;
    font-weight   : 500;
    color         : #212121;
    border-radius : 8px;
    font-family: "Inter" !important;
    background    : #E3F2FD;
    white-space   : nowrap;
    cursor        : pointer
}

.Flag {
    width       : 18px !important;
    height      : 18px !important;
    margin-right: 8px
}