.CongratulationsBottomSheet {
    width          : 86%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin         : auto;
    max-width      : 768px
}

.CongratulationsBottomSheetHeader {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 72px
}

.CongratulationsBottomSheetHeaderText {
    font-size  : 20px;
    color      : #212121;
    margin-left: 8px;
    font-family: "Inter" !important;
    font-weight: 500
}

.CongratulationsBottomSheetBody {
    margin-top     : 16px;
    width          : 100%;
    color          : #212121;
    font-family: "Inter" !important;
    font-weight    : 400;
    text-align     : center;
    font-size      : 14px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.CongratulationsBottomSheetFooter {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between !important;
    align-items    : center;
    margin-top     : 32px;
    margin-bottom  : 32px
}

.CongratulationsBottomSheetButton {
    width          : 100%;
    height         : 48px;
    display        : flex;
    font-weight    : 500;
    outline        : 0;
    font-family: "Inter" !important;
    font-size      : 16px;
    color          : #fff;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    border         : 1px solid #e7e7e7;
    border-radius  : 50px;
    background     : #1976d2;
    text-decoration: none
}

.CongratulationsBottomSheetBodyReward {
    font-family: Product Sans !important;
    font-size  : 16px;
    font-weight: 400;
    color      : #616161;
    margin-top : 24px
}

.CongratulationsBottomSheetBodyRewardNumber {
    color          : #212121;
    font-family: "Inter" !important;
    font-size      : 18px;
    font-weight    : 700;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 8px
}

.CongratulationsBottomSheetBodyRewardCoin {
    margin: 0 4px 0 4px
}

.CongratulationsBottomSheetBodyRewardStar {
    margin: 0 4px 0 4px
}

.GameLeagueNamePromotion {
    color              : #fff;
    width              : 49px;
    height             : 30px;
    display            : flex;
    flex-direction     : row;
    justify-content    : center;
    align-items        : center;
    font-size          : 32px;
    border-radius      : 2px;
    font-family        : "EB Garamond", serif;
    font-optical-sizing: auto;
    font-style         : normal;
    z-index            : 1;
    padding            : 0;
    margin-top         : -7px
}

.PromotionRewardMulti {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 91%;
    height         : fit-content;
    background     : #F5F5F5;
    border-radius  : 8px;
    height         : 147px;
    margin-top     : 16px
}

.PromotionRewardMultiSum {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 91%;
    height         : fit-content;
    background     : #F5F5F5;
    border-radius  : 8px;
    height         : 48px;
    margin-top     : 16px
}

.PromotionRewardMultiSumTitle {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    color          : #616161;
    font-family: "Inter" !important;
    font-size      : 14px;
    font-weight    : 400
}

.PromotionRewardMultiSumDes {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-left    : 8px;
    font-family: "Inter" !important;
    font-size      : 14px;
    font-weight    : 700
}

.PromotionRewardMultiProgress {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    margin-right   : 16px;
    height         : 92px
}

.PromotionRewardMultiNames {
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : center;
    height         : 100%
}

.PromotionRewardMultiPrev {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start
}

.PromotionRewardMultiTarget {
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : start
}

.PromotionRewardMultiPrevName {
    color      : #212121;
    font-family: "EB Garamond", serif;
    font-size  : 14px;
    font-weight: 700
}

.PromotionRewardMultiNumber {
    color      : #757575;
    font-family: "Inter" !important;
    font-size  : 14px;
    font-weight: 400
}

.PromotionRewardMultiBordering {
    border: 1px dashed #e0e0e0;
    height: 12px
}

.PromotionRewardMultiPrevReward {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 8px;
    font-family: "Inter" !important;
    font-size      : 14px;
    font-weight    : 700
}