.LoginTelegramBackIcon,
.LoginTelegramBodyText,
.LoginTelegramHeader,
.LoginTelegramInput,
.LoginTelegramLink,
.LoginTelegramLinkDiv {
    display: flex;
    display: flex
}

.LoginTelegramCountryTextField input,
.ResendCode {
    text-align: center
}

.LoginTelegramContainer {
    width    : 86.6%;
    max-width: 796px;
    margin   : auto auto 2rem;
    height   : 100vh;
    position : relative
}

.LoginTelegramHeader {
    width          : 100%;
    height         : 56px;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center
}

.LoginTelegramBackIcon {
    width          : 48px;
    height         : 48px;
    flex-direction : row;
    align-items    : center;
    justify-content: flex-start
}

.RegisterBackIconLoginTelegram {
    margin-right   : 16px;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.LoginTelegramBackIconImg {
    width : 24px;
    height: 24px
}

.LoginTelegramHeaderText {
    color      : #212121;
    font-size  : 20px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.LoginTelegramBodyText,
.LoginTelegramBodyTextCountry,
.SearchDiv input {
    font-weight: 400;
    font-family: "Inter" !important;
}

.LoginTelegramBodyText {
    color          : #212121;
    font-size      : 14px;
    width          : 100%;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 56px;
    border         : 1px solid #e0e0e0;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center
}

.LoginTelegramBodyTextCountry {
    margin-left: 16px;
    color      : #757575;
    font-size  : 16px
}

.PhoneNumberInBottomSheet {
    font-family: "Inter" !important;
    font-weight: 700;
    font-size  : 14px
}

.BannedImage {
    margin-right: 8px
}

.RegisterBeforeBottomSheetBodyBanned {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.LoginTelegramBodyTextIcon {
    color       : #757575;
    margin-right: 14px
}

.LoginTelegramBody {
    width     : 100%;
    margin-top: 24px
}

.LoginTelegramInput {
    width          : 100%;
    flex-direction : row;
    align-items    : center;
    justify-content: space-between;
    height         : 126px !important
}

.LoginTelegramCountryTextField {
    width       : 20%;
    margin-right: 18px !important
}

.LoginTelegramPhoneTextField {
    width: 80%
}

.GetCodePhoneTextField {
    width: 100%
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin            : 0
}

.LoginTelegramFooter {
    position: absolute;
    bottom  : 0;
    width   : 100%
}

.LoginTelegramLinkDiv {
    width          : 100%;
    flex-direction : row;
    justify-content: space-between
}

.LoginTelegramLink {
    width          : 56px;
    height         : 56px;
    background     : #1976d2;
    color          : #fff;
    font-size      : 16px;
    font-family: "Inter" !important;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    text-decoration: none;
    border-radius  : 100%;
    margin-bottom  : 22px;
    outline        : 0;
    border         : none
}

.LoginTelegramTimer,
.SearchDiv {
    flex-direction: row;
    width         : 100%;
    display       : flex
}

@media (min-width:980px) {
    .LoginTelegramLink:hover {
        cursor    : pointer;
        background: #1565c0
    }
}

.LoginTelegramTimer {
    justify-content: center;
    align-items    : center;
    margin-bottom  : 23px;
    font-size      : 14px;
    font-weight    : 500;
    color          : #212121
}

.ResendCode {
    color      : #1976d2;
    font-size  : 14px;
    font-weight: 500;
    cursor     : pointer
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    height: 56px !important
}

.SearchDiv {
    align-items: center
}

.SearchDiv input {
    width    : 100%;
    border   : #ffdead;
    height   : 38px;
    font-size: 14px;
    color    : #212121;
    outline  : 0
}

.CloseModalIcon {
    color : #9e9e9e;
    width : 16px;
    height: 16px;
    cursor: pointer
}

.ListCountryItem {
    height     : 48px;
    width      : 100%;
    font-family: "Inter" !important;
    font-size  : 14px
}

.ListCountryItemLast {
    height        : 48px;
    width         : 100%;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background-color      : #fff;
    color                 : rgb(0 0 0 / .87);
    -webkit-transition    : box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    transition            : box-shadow .3s cubic-bezier(.4, 0, .2, 1);
    border-radius         : 4px;
    box-shadow            : 0 11px 15px -7px rgb(0 0 0 / .2), 0 24px 38px 3px rgb(0 0 0 / .14), 0 9px 46px 8px rgb(0 0 0 / .12);
    margin                : 32px;
    position              : relative;
    overflow-y            : auto;
    display               : -webkit-box;
    display               : -webkit-flex;
    display               : -ms-flexbox;
    display               : flex;
    -webkit-flex-direction: column;
    -ms-flex-direction    : column;
    flex-direction        : column;
    max-height            : calc(100% - 64px);
    max-width             : 600px;
    height                : 90% !important;
    width                 : 100% !important
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    margin        : 0;
    font-family   : Roboto, Helvetica, Arial, sans-serif;
    font-weight   : 500;
    font-size     : 1.25rem;
    line-height   : 1.6;
    letter-spacing: .0075em;
    padding       : 8px 24px !important;
    -webkit-flex  : 0 0 auto;
    -ms-flex      : 0 0 auto;
    flex          : 0 0 auto
}

.LoginTelegramWarning {
    font-size  : 14px;
    font-family: "Inter" !important;
    font-weight: 400;
    width      : 100%;
    margin     : 32px auto auto;
    line-height: 20px
}