.GiftContainer {
    width     : 100%;
    max-width : 768px;
    margin    : auto;
    margin-top: 56px
}

.GiftInner {
    width          : 94%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 56px;
    margin         : auto;
    cursor         : pointer;
    margin-top     : 12px;
    padding        : 0 0 0 1rem
}

.GiftInner:hover {
    background: #F5F5F5
}

.GiftTextIcon {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.GiftText {
    margin-left: 16px;
    font-size  : 16px;
    font-weight: 400;
    font-family: "Inter" !important;
    color      : #212121
}

.GiftArrow {
    margin-right: 16px
}

.GiftCodeContainer {
    width        : 100%;
    max-width    : 768px;
    margin       : auto;
    height       : 96dvh;
    margin-bottom: 32px
}

.GiftCodeBody {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : center;
    height         : 98dvh
}

.GiftCodeInput {
    width     : 91%;
    max-width : 706px;
    margin-top: 48px
}

.GiftCodeBodyText {
    font-size  : 14px;
    font-weight: 400;
    font-family: "Inter" !important;
    color      : #212121;
    margin-top : 32px
}

.ButtonRecordContainer {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.GiftCodeRecord {
    width        : 91%;
    border       : none;
    outline      : none;
    height       : 48px;
    background   : #1976D2;
    border-radius: 30px;
    color        : #fff;
    margin       : auto;
    cursor       : pointer;
    font-weight  : 400;
    font-size    : 16px
}

.GiftCodeRecordDisable {
    width        : 91%;
    border       : none;
    outline      : none;
    height       : 48px;
    background   : #e0e0e0;
    border-radius: 30px;
    color        : #616161;
    margin       : auto;
    cursor       : pointer;
    font-weight  : 400;
    font-size    : 16px
}

.TextFieldGiftCode {
    margin-top: 24px
}