@media (max-width:980px) {
    .GameCoin {
        width    : 98%;
        height   : 80px;
        max-width: 769px;
        margin   : auto
    }
}

@media (min-width:980px) {
    .GameCoin {
        width     : 100%;
        height    : 80px;
        max-width : 769px;
        margin    : auto;
        margin-top: 8px
    }
}

.SkeletonInCoinGame {
    width     : 99% !important;
    height    : 80px !important;
    margin    : auto !important;
    margin-top: 16px !important;
    max-width : 658px !important
}

.GameCoinContainer {
    border         : 1px solid #E0E0E0;
    border-radius  : 8px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    padding        : 14px 16px;
    background     : #fff
}

.GameCoinContainer:hover {
    background: #e0e0e0;
    cursor    : pointer
}

.GameCoinItem {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center
}

.GameCoinItemCreator {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center
}

.CreatorTextInCoin {
    font-size  : 20px;
    font-weight: 700;
    font-family: "EB Garamond";
    color      : #212121;
    margin-left: 16px
}

.GameCoinItemInner {
    display        : flex;
    flex-direction : row;
    justify-content: start;
    align-items    : center
}

.GameCoinLeagueTitle {
    font-size  : 14px;
    font-weight: 700;
    font-family: "EB Garamond";
    color      : #212121;
    margin-left: 4px
}

.GameCoinLeagueIcon {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.GameCoinLeagueIcon img {
    width : 20px;
    height: 20px
}

.GameCoinLeagueArrow {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-left    : 8px
}

.GameCoinLeagueArrow1 {
    width : 20px !important;
    height: 20px !important
}

.GameCoinRemainJoin {
    font-size  : 12px;
    color      : #616161;
    font-weight: 400;
    font-family: "Inter" !important;
}

.GameCoinRemainJoinBold {
    color: #212121
}

.ProgressStyleCoin {
    border       : 1px solid #E0E0E0 !important;
    height       : 16px !important;
    background   : #F5F5F5 !important;
    color        : #BF8970 !important;
    border-radius: 10px !important
}

.ProgressStyleCoin:hover {
    background: #fff !important
}

@media (max-width:980px) {
    .GameCoinBoxProgress {
        width        : 100%;
        height       : 18px;
        margin-top   : 3px;
        margin-bottom: 4px
    }
}

@media (min-width:980px) {
    .GameCoinBoxProgress {
        width        : 100%;
        height       : 18px;
        margin-top   : 3px;
        margin-bottom: 4px
    }
}

.GameCoinStartAndEnd {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center
}

.GameCoinStart {
    color      : #212121;
    font-size  : 12px;
    font-weight: 400;
    font-family: "Inter" !important;
}

.GameCoinEnd {
    color      : #212121;
    font-size  : 12px;
    font-weight: 500;
    font-family: "Inter" !important;
}

.GameCoinStartBetween {
    color      : #212121;
    font-size  : 12px;
    font-weight: 500;
    font-family: "Inter" !important;
    margin     : 0 2px
}