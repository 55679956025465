.GetLinkUsername {
    width    : 90%;
    margin   : auto;
    position : relative;
    height   : 100%;
    max-width: 768px
}

.GetLinkUsernameHeader {
    display        : flex;
    flex-direction : row;
    justify-content: flex-start;
    align-items    : center;
    height         : 40px;
    margin-top     : 16px
}

.GetLinkUsernameHeaderText {
    font-size  : 20px;
    font-weight: 500;
    font-family: "Inter" !important;
    color      : #212121;
    margin-left: 16px
}

.GetLinkUsernameHeaderImg {
    width : 24px;
    height: 24px
}

.GetLinkUsernameContent {
    margin-top     : 8px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.GetLinkUsernameContentText {
    margin-top : 24px;
    font-size  : 14px;
    font-weight: 400;
    color      : #212121;
    font-family: "Inter" !important;
    text-align : left;
    width      : 100%
}

.GetLinkUsernameContentText1 {
    margin-top : 32px;
    font-size  : 14px;
    font-weight: 400;
    color      : #212121;
    font-family: "Inter" !important;
    text-align : left;
    width      : 100%
}

.GetLinkUsernameContentTextUl {
    margin-top : 16px;
    font-size  : 14px;
    font-weight: 400;
    color      : #212121;
    font-family: "Inter" !important;
    width      : 100%
}

.GetLinkUsernameContentTextUl li {
    margin-top: 8px
}

.GetLinkUsernameFooter {
    position: absolute;
    bottom  : 22px;
    width   : 100%
}

.GetLinkUsernameFooterButton {
    background   : #1976D2;
    font-size    : 16px;
    font-weight  : 500;
    color        : #fff;
    font-family: "Inter" !important;
    width        : 100%;
    height       : 48px;
    outline      : none;
    border       : none;
    border-radius: 30px;
    cursor       : pointer
}

@media (min-width:980px) {
    .GetLinkUsernameFooterButton:hover {
        cursor    : pointer;
        background: #1565C0
    }
}

.GetLinkUsernameInputContainer {
    width     : 100%;
    margin-top: 24px
}

.GetLinkUsernameInput {
    width      : 100%;
    font-size  : 16px;
    font-weight: 400;
    color      : #212121;
    font-family: "Inter" !important;
}

.GetLinkUsernameInput label {
    color: #212121
}

.ShowPriceAndChannelBottomSheetBody {
    margin-top     : 16px;
    width          : 100%;
    color          : #212121;
    font-family: "Inter" !important;
    font-weight    : 400;
    text-align     : center;
    font-size      : 14px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center
}

.ShowPriceAndChannelItem {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    height         : 48px
}

.ShowPriceAndChannelTitle {
    color      : #616161;
     font-family: "Inter" !important;
    font-weight: 400;
    font-size  : 14px
}

.ShowPriceAndChannelValue {
    color         : #212121;
    font-family: "Inter" !important;
    font-weight   : 700;
    font-size     : 16px;
    display       : flex;
    flex-direction: row;
    align-items   : center
}

.PersonIcon {
    width      : 20px !important;
    height     : 20px !important;
    color      : #616161;
    margin-left: 4px
}

.ViewIconInBottomSheet {
    width      : 20px !important;
    height     : 20px !important;
    color      : #616161 !important;
    margin-left: 4px
}

.DollarSign {
    color       : #616161;
    margin-left : 4px;
    margin-right: 5px
}

.CoinSign {
    color      : #616161;
    margin-left: 4px
}

.BottomSheetShowPriceAndChannelButton {
    width          : 100%;
    height         : 48px;
    color          : #fff;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-family: "Inter" !important;
    font-weight    : 500;
    outline        : none;
    border         : 1px solid #E7E7E7;
    border-radius  : 50px;
    background     : #1976D2;
    text-decoration: none;
    cursor         : pointer
}

.BottomSheetShowPriceAndChannelButtonDisable {
    width          : 100%;
    height         : 48px;
    color          : #fff;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    font-family: "Inter" !important;
    font-weight    : 500;
    outline        : none;
    border         : 1px solid #E7E7E7;
    border-radius  : 50px;
    background     : #1976D2;
    text-decoration: none;
    cursor         : not-allowed
}

@media (min-width:980px) {
    .BottomSheetShowPriceAndChannelButton:hover {
        cursor    : pointer;
        background: #1565C0
    }

    .GetLinkUsernameHeaderLink:hover {
        background   : #F5F5F5;
        border-radius: 100%
    }
}

.GetLinkUsernameHeaderLink {
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    width          : 40px;
    height         : 40px;
    cursor         : pointer
}