.RegisterButton {
    width          : 100%;
    border-radius  : 30px;
    color          : #1976d2;
    font-weight    : 500;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 48px;
    border         : 1px solid #e0e0e0;
    background     : #fff;
    font-family: "Inter" !important;

    text-decoration: none
}

.RegisterButtonLuncher {
    width          : 100%;
    border-radius  : 30px;
    color          : #fff;
    background     : #1976D2;
    font-weight    : 500;
    font-size      : 16px;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 48px;
    border         : 1px solid #e0e0e0;
    font-family: "Inter" !important;

    text-decoration: none
}

@media (min-width:980px) {
    .RegisterButton:hover {
        background: #e3f2fd;
        cursor    : pointer
    }
}