.SaleBannedUserBottomSheet {
    width          : 94%;
    margin         : auto;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    max-width      : 768px
}

.SaleBannedUserBottomSheetTitle {
    font-size      : 20px;
    font-weight    : 500;
    font-family: "Inter" !important;
    color          : #212121;
    margin-top     : 28px;
    text-align     : center;
    display        : flex;
    flex-direction : row;
    align-items    : center;
    justify-content: center
}

.BannedIcon {
    margin-right: 4px
}

.SaleBannedUserBottomSheetDes {
    font-size  : 14px;
    font-weight: 400;
    font-family: "Inter" !important;
    color      : #212121;
    margin-top : 24px;
    text-align : left;
    padding    : 0 1rem;
    line-height: 24px
}

.SaleBannedUserBottomSheetFooter {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center
}

.SaleBannedUserBottomSheetButton {
    outline      : 0;
    width        : 100%;
    font-family: "Inter" !important;
    font-size    : 16px;
    font-weight  : 500;
    height       : 48px;
    margin       : 24px 0 21px;
    background   : #1976d2;
    border       : none;
    border-radius: 30px;
    color        : #fff
}

.TerminatingSessionsList {
    width : 86%;
    margin: auto
}

.TerminatingSessionsList ul {
    width: 100%
}

.TerminatingSessionsList ul li {
    font-family: "Inter" !important;
    font-size    : 14px;
    font-weight  : 400;
    color        : #212121;
    margin-bottom: 8px
}