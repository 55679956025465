.GoogleLoginButton {
    background     : #1976d2;
    width          : 100%;
    outline        : 0;
    border         : none;
    color          : #fff;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    height         : 48px;
    border-radius  : 30px;
    font-size      : 16px;
    margin-top     : 16px;
    font-family: "Inter" !important;

    margin-bottom  : 16px;
    position       : relative;
    z-index        : 100
}

@media (min-width:980px) {
    .GoogleLoginButton:hover {
        background: #1565c0;
        cursor    : pointer
    }
}

.GoogleLoginButtonIcon {
    margin-right: 8px;
    width       : 24px;
    height      : 24px
}