.Header {
    position  : fixed;
    top       : 0;
    width     : 100%;
    height    : 56px;
    background: #fff;
    z-index   : 1
}

.HeaderContainer {
    width          : 90%;
    height         : 56px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    align-items    : center;
    margin         : auto;
    max-width: 768px;
}

.HeaderCoin {
    font-weight   : 700;
    font-size     : 14px !important;
    font-family: "Inter" !important;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    background    : #FEF7E6;
    /* background : blue; */
    border-radius : 35px;
    padding       : .1rem;
    cursor        : pointer;
}

.HeaderCoinImg {
    margin-left: 4px
}

.AddCoinIcon {
    width       : 24px !important;
    height      : 24px !important;
    color       : #1565C0;
    margin-right: 8px;
}

.HeaderLogo {
    display       : flex;
    flex-direction: row;
    background    : #CDE9FF;
    width         : 101px;
    height        : 40px;
    border-radius : 33px;
    cursor        : pointer;
}

.HeaderLogoPt {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    font-weight    : 400;
    font-size      : 12px;
    font-family: "Inter" !important;
}

.BottomSheetUlXpUl {
    width    : 100%;
    max-width: 768px;
}
.BottomSheetUlXpUl li{
    font-weight    : 400;
    font-size      : 16px;
    font-family: "Inter" !important;
    color: #212121;
}